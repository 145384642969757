import _transform from 'lodash/transform';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';

//  ██╗  ██╗███████╗██╗     ██████╗ ███████╗██████╗ ███████╗
//  ██║  ██║██╔════╝██║     ██╔══██╗██╔════╝██╔══██╗██╔════╝
//  ███████║█████╗  ██║     ██████╔╝█████╗  ██████╔╝███████╗
//  ██╔══██║██╔══╝  ██║     ██╔═══╝ ██╔══╝  ██╔══██╗╚════██║
//  ██║  ██║███████╗███████╗██║     ███████╗██║  ██║███████║
//  ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝╚══════╝
//

/**
 * https://gist.github.com/Yimiprod/7ee176597fef230d1451
 *
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  function changes(object, base) {
    return _transform(object, function(result, value, key) {
      // Ignore all keys beginning with underscore
      if (!key.toString().startsWith('_')) {
        if (!_isEqual(value, base[key])) {
          result[key] =
            _isObject(value) && _isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      }
    });
  }
  return changes(object, base);
}

/**
 * Migrate the delta state to format compatible with
 * server-side API.
 *
 * @param {object} diffState - the delta state to be saved
 */
export function formatForAPI(diffState) {
  // shallow copy, and leave out object references
  const apiSaveFormat = Object.keys(diffState).reduce(function(ret, index) {
    if (!isObject(diffState[index])) ret[index] = diffState[index];
    return ret;
  }, {});

  // migrate section order (from <string> "section-1" to <number> 1)
  if (apiSaveFormat.sectionOrder) {
    apiSaveFormat.sectionOrder = apiSaveFormat.sectionOrder.map(item =>
      parseInt(item.split('-')[1], 10)
    );
  }

  // migrate sections
  if (isObject(diffState.sections)) {
    const itemSectionArray = [];
    const sectionKeys = Object.keys(diffState.sections);

    for (let i = 0; i < sectionKeys.length; i++) {
      const thisSection = { ...diffState.sections[sectionKeys[i]] };

      if (!isEmptyObject(thisSection)) {
        thisSection.id = parseInt(sectionKeys[i].split('-')[1], 10);
        itemSectionArray.push(thisSection);
      }
    }

    if (itemSectionArray.length > 0) {
      apiSaveFormat.itemSections = itemSectionArray;
    }
  }

  // migrate items
  if (isObject(diffState.items)) {
    const itemArray = [];
    const itemKeys = Object.keys(diffState.items);

    for (let i = 0; i < itemKeys.length; i++) {
      const thisItem = { ...diffState.items[itemKeys[i]] };

      if (!isEmptyObject(thisItem)) {
        thisItem.id = parseInt(itemKeys[i].split('-')[1], 10);
        itemArray.push(thisItem);
      }
    }

    if (itemArray.length > 0) {
      apiSaveFormat.items = itemArray;
    }
  }

  // console.log(
  //   'diffed object ready to send to api endpoint, apiSaveFormat',
  //   apiSaveFormat
  // );
  return apiSaveFormat;
}

/**
 * Check if passed in variable is an object.
 *
 * @param {*} obj
 */
export function isObject(obj) {
  return obj && typeof obj === 'object' && obj.constructor === Object;
}

/**
 * Check if object is empty/has zero values inside
 *
 * @param {*} obj
 */
export function isEmptyObject(obj) {
  return !isObject(obj) || Object.keys(obj).length === 0;
}
