import { AUTH_USER, AUTH_ERROR, HIDE_GLOBAL_LOADER } from './types';

export * from './contractActions';
export * from './uiActions';
export * from './authActions';
export * from './reportActions';
export * from './userActions';
export * from './billingActions';

//Auth Actions
export const signup = (formProps, callback) => async dispatch => {
  try {
    // const response = await axios.post('https://jsonplaceholder.typicode.com/posts', formProps)

    /* placeholder until api is up */
    const response = {};
    response.data = {};
    response.data.token = 'jsonwebtoken';
    /* */

    dispatch({
      type: AUTH_USER,
      payload: response.data.token,
    });

    localStorage.setItem('c-token', response.data.token);

    callback();
  } catch (e) {
    console.log('sign up error: ', e);
    dispatch({ type: AUTH_ERROR, payload: 'Sing Up Error' });
    dispatch({ type: HIDE_GLOBAL_LOADER, payload: {} });
  }
};

export const signin = (formProps, callback) => async dispatch => {
  try {
    // const response = await axios.post('https://jsonplaceholder.typicode.com/posts', formProps)

    /* placeholder until api is up */
    const response = {};
    response.data = {};
    response.data.token = 'jsonwebtoken';
    /* */

    dispatch({
      type: AUTH_USER,
      payload: response.data.token,
    });

    localStorage.setItem('c-token', response.data.token);

    callback();
  } catch (e) {
    console.log('sign up error: ', e);
    dispatch({ type: AUTH_ERROR, payload: 'Sign in Error' });
    dispatch({ type: HIDE_GLOBAL_LOADER, payload: {} });
  }
};
