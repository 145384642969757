import * as types from '../actions/types';

const INITIAL_STATE = {
  productFocus: '',
  itemFocusLoading: false,
  itemDeleteLoading: false,
  itemAddLoading: false,
  itemAddLoadingId: '',
  errorMessage: '',
  tabFocus: 'settings-activity-tab',
  showGlobalLoader: false,
  contractLoading: false,
  contractLoadingNotFound: false,
  contractLoadingError: false,
  contractIsAutosaving: false,
  contractAwaitingAutosave: false,
  contractAutosaveError: false,
  contractAutosaveSuccess: false,
  contractAutosaveSuccessTimestamp: null,
  loaderMessage: '',
  executedPdfLoading: false,
  executedPdfGenerating: false,
  executedPdfGeneratingMessage: '',
  notifications: [],
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SHOW_NOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    }

    case types.CLOSE_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.filter(
          item => item.id !== action.payload
        ),
      };
    }

    case types.ITEM_LOADING_UI: {
      return {
        ...state,
        productFocus: action.payload,
        itemFocusLoading: true,
      };
    }

    case types.ITEM_DELETE_LOADING: {
      let newFocus = action.payload;
      if (state.productFocus === newFocus && !state.itemFocusLoading) {
        newFocus = '';
      }
      return {
        ...state,
        productFocus: newFocus,
        itemDeleteLoading: true,
      };
    }

    case types.ITEM_DELETE_LOADING_DONE: {
      return {
        ...state,
        productFocus: '',
        itemDeleteLoading: false,
      };
    }

    case types.ADD_CONTRACT_ITEM_LOADING: {
      return {
        ...state,
        itemAddLoading: true,
        itemAddLoadingId: action.payload,
      };
    }

    case types.ADD_CONTRACT_ITEM_LOADING_DONE: {
      return {
        ...state,
        itemAddLoading: false,
        itemAddLoadingId: '',
      };
    }

    case types.SHOW_GLOBAL_LOADER:
      return {
        ...state,
        showGlobalLoader: true,
        loaderMessage: action.payload,
      };
    case types.HIDE_GLOBAL_LOADER:
      return { ...state, showGlobalLoader: false, loaderMessage: '' };
    case types.CONTRACT_AUTOSAVE_AWAITING:
      return { ...state, contractAwaitingAutosave: true };
    case types.CONTRACT_AUTOSAVE_STARTED:
      return { ...state, contractIsAutosaving: true };
    case types.CONTRACT_AUTOSAVE_SUCCEEDED:
      return {
        ...state,
        contractIsAutosaving: false,
        contractAwaitingAutosave: false,
        contractAutosaveError: false,
        contractAutosaveSuccess: true,
        contractAutosaveSuccessTimestamp: Date.now(),
      };
    case types.TEMPLATE_AUTOSAVE_SUCCEEDED:
      return {
        ...state,
        contractIsAutosaving: false,
        contractAwaitingAutosave: false,
        contractAutosaveError: false,
        contractAutosaveSuccess: true,
        contractAutosaveSuccessTimestamp: Date.now(),
      };
    case types.CONTRACT_AUTOSAVE_CANCELED:
      return {
        ...state,
        contractIsAutosaving: false,
        contractAwaitingAutosave: false,
      };
    case types.CONTRACT_AUTOSAVE_FAILED:
      return {
        ...state,
        contractIsAutosaving: false,
        contractAutosaveError: true,
      };
    case types.FOCUS_PRODUCT:
      let newFocus = action.payload;
      if (state.productFocus === newFocus && !state.itemFocusLoading) {
        newFocus = '';
      }
      return { ...state, productFocus: newFocus, itemFocusLoading: false };
    case types.FOCUS_PRODUCT_ERROR:
      return { ...state, errorMessage: action.payload };
    case types.FOCUS_ACTIVITY_TAB:
      return { ...state, tabFocus: action.payload };

    case types.CONTRACT_LOADING:
      return {
        ...state,
        contractLoading: true,
      };

    case types.CONTRACT_LOADING_DONE:
      return {
        ...state,
        contractLoading: false,
        contractLoadingError: false,
        contractLoadingNotFound: false,
      };

    case types.CONTRACT_LOADING_NOT_FOUND:
      return {
        ...state,
        contractLoading: false,
        contractLoadingError: true,
        contractLoadingNotFound: true,
      };

    case types.CONTRACT_LOADING_FAILED:
      return {
        ...state,
        contractLoading: false,
        contractLoadingError: true,
      };

    case types.RESET_BUILDER_UI:
      return {
        ...state,
        productFocus: '',
        itemFocusLoading: false,
        itemDeleteLoading: false,
        itemAddLoading: false,
        itemAddLoadingId: false,
        errorMessage: false,
      };

    case types.EXECUTED_PDF_LOADING:
      return {
        ...state,
        executedPdfLoading: !state.executedPdfLoading,
      };

    case types.EXECUTED_PDF_GENERATING:
      return {
        ...state,
        executedPdfGenerating: true,
        executedPdfGeneratingMessage: action.payload,
      };

    case types.EXECUTED_PDF_GENERATING_DONE:
      return {
        ...state,
        executedPdfGenerating: false,
        executedPdfGeneratingMessage: '',
      };

    case types.EXECUTED_PDF_GENERATING_ERROR:
      return {
        ...state,
        executedPdfGenerating: false,
        errorMessage: action.payload,
      };

    case types.SHOW_FEEDBACK_MODAL: {
      return {
        ...state,
        showFeedback: true,
      };
    }

    default:
      return state;
  }
}
