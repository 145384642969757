import * as types from '../actions/types';

const INITIAL_STATE = {
  teams: [],
  usergroups: [],
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_USERGROUPS: {
      const usergroups = action.payload;

      return { ...state, usergroups };
    }

    case types.SEARCH_USERS_STARTED: {
      return { ...state, loadingUsers: true };
    }

    case types.SEARCH_USERS: {
      return { ...state, loadingUsers: false };
    }

    default:
      return state;
  }
}
