import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import DashboardIndex from './DashboardIndex';

const Contract = props => (
  <Route
    key={1}
    path={props.match.url + '/'}
    exact={true}
    component={DashboardIndex}
    props={props}
  />
);

export default withRouter(Contract);
