import axios from 'axios';
import config from '../config';
import * as types from './types';

axios.defaults.withCredentials = true;
const api = config.api.URL;

export const fetchUsers = (
  query = '',
  group = '',
  suspended = '',
  callBack = () => {}
) => async dispatch => {
  try {
    dispatch({ type: types.SEARCH_USERS_STARTED });

    const filter = [];
    if (query) filter.push(`userQuery=${query}`);
    if (group) filter.push(`group=${group}`);
    if (suspended) filter.push(`suspended=${suspended}`);

    const response = await axios.get(`${api}/users?${filter.join('&')}`);
    if (response.status === 200) {
      dispatch({
        type: types.SEARCH_USERS,
        payload: response.data,
      });
      callBack();
    }
  } catch (e) {
    if (e.response && e.response.status === 403) {
      dispatch({
        type: types.FETCH_USERS_DENIED,
        payload: `Not Authorized`,
      });
    } else {
      dispatch({
        type: types.SEARCH_USERS_ERROR,
        payload: 'Search Users Error',
      });
    }
  }
};

export const fetchUserGroups = () => async dispatch => {
  try {
    dispatch({
      type: types.FETCH_USERGROUPS_STARTED,
    });
    const response = await axios.get(`${api}/usergroups/`);
    dispatch({
      type: types.FETCH_USERGROUPS,
      payload: response.data,
    });
  } catch (e) {
    console.log('fetch usergroups error: ', e);
    dispatch({
      type: types.FETCH_USERGROUPS_ERROR,
      payload: 'Fetch usergroups Error',
    });
  } finally {
    dispatch({
      type: types.FETCH_USERGROUPS_FINISHED,
    });
  }
};

export const createUser = (user, cb = () => {}) => async dispatch => {
  try {
    dispatch({
      type: types.CREATING_NEW_USER,
    });

    const response = await axios.post(`${api}/users/`, user);

    dispatch({
      type: types.CREATE_NEW_USER_FINISHED,
      payload: response.data,
    });

    cb();
  } catch (e) {
    let msg = e.message;
    if (e.response && e.response.data && e.response.data.error) {
      msg = e.response.data.error.message;
    }

    dispatch({
      type: types.CREATE_NEW_USER_ERROR,
      payload: msg,
    });
    cb(msg);
  }
};

export const editUser = (user, cb = () => {}) => async dispatch => {
  try {
    dispatch({
      type: types.UPDATING_USER,
    });

    const response = await axios.put(`${api}/users/`, user);

    dispatch({
      type: types.UPDATE_USER_FINISHED,
      payload: response.data,
    });

    cb();
  } catch (e) {
    dispatch({
      type: types.UPDATE_USER_ERROR,
      payload: e,
    });
  } finally {
    dispatch({
      type: types.UPDATING_USER_DONE,
    });
  }
};

export const deleteUser = (user, cb = () => {}) => async dispatch => {
  try {
    dispatch({
      type: types.UPDATING_USER,
    });

    //const response = await axios.delete(`${api}/users/${user.id}`, user);

    // dispatch({
    //   type: types.DELETE_USER_FINISHED,
    //   payload: response.data.user,
    // });

    await axios.delete(`${api}/users/${user.id}`, user);
    const response = await axios.put(`${api}/users/`, user);
    dispatch({
      type: types.UPDATE_USER_FINISHED,
      payload: response.data,
    });

    cb();
  } catch (e) {
    dispatch({
      type: types.DELETE_USER_ERROR,
      payload: e,
    });
  } finally {
    dispatch({
      type: types.UPDATING_USER_DONE,
    });
  }
};
