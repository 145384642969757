import * as types from '../actions/types';
import { notification } from 'antd';

//  ███╗   ███╗██╗██████╗ ██████╗ ██╗     ███████╗██╗    ██╗ █████╗ ██████╗ ███████╗
//  ████╗ ████║██║██╔══██╗██╔══██╗██║     ██╔════╝██║    ██║██╔══██╗██╔══██╗██╔════╝
//  ██╔████╔██║██║██║  ██║██║  ██║██║     █████╗  ██║ █╗ ██║███████║██████╔╝█████╗
//  ██║╚██╔╝██║██║██║  ██║██║  ██║██║     ██╔══╝  ██║███╗██║██╔══██║██╔══██╗██╔══╝
//  ██║ ╚═╝ ██║██║██████╔╝██████╔╝███████╗███████╗╚███╔███╔╝██║  ██║██║  ██║███████╗
//  ╚═╝     ╚═╝╚═╝╚═════╝ ╚═════╝ ╚══════╝╚══════╝ ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝
//

let notificationId = 0;
const notificationDuration = 5000;

export default ({ dispatch }) => next => action => {
  next(action); //pass action to the rest of the middleware

  if (action.type === types.CLOSE_NOTIFICATION) {
    notification.close(action.payload);
  } else {
    const data = {
      id: `noti-${notificationId}`,
      type: '',
      title: 'Notification',
      message: action.payload,
    };

    const successActionsToNotify = [];
    const errorActionsToNotify = [
      types.AUTH_ERROR,
      types.CONTRACT_LOADING_FAILED,
      types.CONTRACT_LOADING_NOT_FOUND,
      types.CREATE_CONTRACT_ERROR,
      types.CREATE_TEMPLATE_ERROR,
      types.DELETE_CONTRACT_ERROR,
      types.CONTRACT_AUTOSAVE_FAILED,
      types.CREATE_NEW_USER_ERROR,
      types.FETCH_USERS_DENIED,
    ];
    const infoActionsToNotify = [];

    // notifications only triggered for specific actions
    if (successActionsToNotify.includes(action.type)) {
      data.type = 'success';
    } else if (errorActionsToNotify.includes(action.type)) {
      data.type = 'error';
      data.title = 'Error';
    } else if (infoActionsToNotify.includes(action.type)) {
      data.type = 'info';
    }

    if (data.type.length > 0) {
      dispatch({
        type: types.SHOW_NOTIFICATION,
        payload: data,
      });

      notification[data.type]({
        key: data.id,
        message: data.title,
        description: data.message,
        duration: 0,
      });

      notificationId++;

      setTimeout(() => {
        dispatch({
          type: types.CLOSE_NOTIFICATION,
          payload: data.id,
        });
      }, notificationDuration);
    }
  }
};
