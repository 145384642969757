import debounce from 'lodash.debounce';
import axios from 'axios';
import config from '../config';
import {
  difference,
  formatForAPI,
  isEmptyObject,
} from './modules/autoSaveModules';
import * as types from '../actions/types';

axios.defaults.withCredentials = true;
const api = config.api.URL;
let originalHistory = null;

//  ██╗  ██╗███████╗██╗     ██████╗ ███████╗██████╗ ███████╗
//  ██║  ██║██╔════╝██║     ██╔══██╗██╔════╝██╔══██╗██╔════╝
//  ███████║█████╗  ██║     ██████╔╝█████╗  ██████╔╝███████╗
//  ██╔══██║██╔══╝  ██║     ██╔═══╝ ██╔══╝  ██╔══██╗╚════██║
//  ██║  ██║███████╗███████╗██║     ███████╗██║  ██║███████║
//  ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝╚══════╝
//

/**
 * Save the current contract state to the server. This is saved
 * via a partial, delta state.
 *
 * @param {object} dispatch - the dispatch object from middleware
 * @param {object} originalState - pre-change state to diff against
 * @param {object} currentState - current state to save to server
 */
export async function doAutoSave(dispatch, originalState, currentState) {
  dispatch({ type: types.CONTRACT_AUTOSAVE_STARTED });

  // console.log('~~~~~~~ autoSave function was executed');
  // console.log('~~~~~~~ original', originalState);
  // console.log('~~~~~~~ current', currentState);

  const diffState = difference(currentState, originalState);
  // console.log('diff of the histories using lodash: ', diffState);

  const apiSaveData = formatForAPI(diffState);

  // console.log('apiSaveData: ', apiSaveData);

  if (!isEmptyObject(apiSaveData)) {
    try {
      // console.log('firing update to server: ', currentState);
      const res = await axios.put(
        `${api}/contracts/${currentState.contractId}`,
        apiSaveData
      );
      dispatch({ type: types.CONTRACT_AUTOSAVE_SUCCEEDED, payload: res });
    } catch (e) {
      dispatch({ type: types.CONTRACT_AUTOSAVE_FAILED, error: e });
    }
  } else {
    // console.log('Auto save object was empty. Skipping...');
    dispatch({ type: types.CONTRACT_AUTOSAVE_CANCELED });
  }

  originalHistory = null; // todo: could move this so we catch unsaved changes on from failed auto saves, also could fire one more auto save on finalize
}
const debounceAutoSave = debounce(doAutoSave, 3000);

//  ███╗   ███╗██╗██████╗ ██████╗ ██╗     ███████╗██╗    ██╗ █████╗ ██████╗ ███████╗
//  ████╗ ████║██║██╔══██╗██╔══██╗██║     ██╔════╝██║    ██║██╔══██╗██╔══██╗██╔════╝
//  ██╔████╔██║██║██║  ██║██║  ██║██║     █████╗  ██║ █╗ ██║███████║██████╔╝█████╗
//  ██║╚██╔╝██║██║██║  ██║██║  ██║██║     ██╔══╝  ██║███╗██║██╔══██║██╔══██╗██╔══╝
//  ██║ ╚═╝ ██║██║██████╔╝██████╔╝███████╗███████╗╚███╔███╔╝██║  ██║██║  ██║███████╗
//  ╚═╝     ╚═╝╚═╝╚═════╝ ╚═════╝ ╚══════╝╚══════╝ ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝
//

export default ({ dispatch, getState }) => next => async action => {
  next(action); // pass action to the rest of the middleware
  const state = getState();
  // autosave is only triggered on actions related to contract builder
  if (
    action.type === types.REORDER_SECTIONS ||
    action.type === types.TOGGLE_ITEM_ACTIVE ||
    action.type === types.UPDATE_ITEM_OBJECT ||
    action.type === types.UPDATE_SECTION_OBJECT ||
    action.type === types.UPDATE_ITEM_ATTRIBUTE
  ) {
    // When initial auto save triggered (may not immediately be saved,
    // due to debounce), copy the last saved state, to be diff-ed later
    if (originalHistory === null) {
      const preChangeIndex = Math.max(...Object.keys(state.contract.past));
      // console.log('les go get dat history: ', preChangeIndex);

      // As of (11/16/2018), JSON stringify + parse was a performant way to deep clone an object
      // We expect the Contract object in state to simply be JSON-compliant data,
      // no functions or circular references.
      originalHistory = JSON.parse(
        JSON.stringify(state.contract.past[preChangeIndex])
      );
      // console.log('~~~~~~~~~~~ the original history', originalHistory);
    }

    // console.log(
    //   'autoSave triggered: getState() next action',
    //   state,
    //   next,
    //   action
    // );
    dispatch({ type: types.CONTRACT_AUTOSAVE_AWAITING });
    debounceAutoSave(dispatch, originalHistory, state.contract.present);
    // originalHistory = null;
  } else if (action.type === types.REORDER_PACKAGES) {
    dispatch({ type: types.CONTRACT_AUTOSAVE_STARTED });

    const contract = state.contract.present;
    const listParams = action.payload;

    const body = {
      sourceSection: contract.sections[listParams.source.droppableId],
      destinationSection: contract.sections[listParams.destination.droppableId],
      package: contract.packages[`package-${listParams.draggableId}`],
    };

    try {
      const res = await axios.put(`${api}/packages/reorder`, body);
      dispatch({ type: types.CONTRACT_AUTOSAVE_SUCCEEDED, payload: res });
    } catch (error) {
      console.log('save package reorder error: ', error);
      dispatch({ type: types.CONTRACT_AUTOSAVE_FAILED, error });
    }
  } else if (action.type === types.REORDER_ITEMS) {
    dispatch({ type: types.CONTRACT_AUTOSAVE_STARTED });
    const contract = state.contract.present;
    const listParams = action.payload;
    const body = {
      sourcePackage: contract.packages[listParams.source.droppableId],
      destinationPackage: contract.packages[listParams.destination.droppableId],
      item: contract.items[listParams.draggableId],
    };
    try {
      const res = await axios.put(`${api}/items/reorder`, body);
      dispatch({ type: types.CONTRACT_AUTOSAVE_SUCCEEDED, payload: res });
    } catch (error) {
      console.log('save package reorder error: ', error);
      dispatch({ type: types.CONTRACT_AUTOSAVE_FAILED, error });
    }
  } else if (action.type === types.UPDATE_EDITOR_STATE) {
    dispatch({ type: types.CONTRACT_AUTOSAVE_STARTED });
    const contract = state.contract.present;
    const { itemId, _editorState } = action.payload;
    const item = contract.items[itemId];
    const itemLargeText = item.itemLargeText[0];

    const postBody = {
      html: _editorState,
    };

    try {
      const res = await axios.put(
        `${api}/itemlargetexts/${itemLargeText.id}`,
        postBody
      );
      dispatch({ type: types.CONTRACT_AUTOSAVE_SUCCEEDED, payload: res });
    } catch (error) {
      console.log('save package reorder error: ', error);
      dispatch({ type: types.CONTRACT_AUTOSAVE_FAILED, error });
    }
  }
};
