import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import config from './config';

import Root from './Root';
import App from './components/App';

if (
  process.env.REACT_APP_ENV_MODE === 'stage' ||
  process.env.REACT_APP_ENV_MODE === 'prod'
) {
  Sentry.init({
    dsn: config.sentryDSN,
    environment: process.env.REACT_APP_ENV_MODE,
  });
}

ReactDOM.render(
  <Root>
    <App />
  </Root>,
  document.querySelector('#root')
);
