export const API_ERROR = 'api_error';
export const FETCH_CONTRACTS_ERROR = 'fetch_contracts_error';
export const SEARCH_CONTRACTS_ERROR = 'search_contracts_error';
export const SEARCH_USERS_ERROR = 'search_users_error';
export const FETCH_CONTRACTS = 'fetch_contracts';
export const SEARCH_CONTRACTS = 'search_contracts';
export const SEARCH_USERS = 'search_users';
export const SEARCH_CONSULTANTS = 'search_consultants';
export const SEARCH_USERS_STARTED = 'search_users_started';
export const SELECT_USER = 'select_user';
export const SELECT_USER_ERROR = 'select_user_error';
export const LOAD_CONTRACT_TO_STATE = 'load_contract_to_state';
export const FETCH_TEMPLATES_ERROR = 'fetch_templates_error';
export const FETCH_TEMPLATES = 'fetch_templates';
export const FETCH_CONTRACT_LOG_ERROR = 'fetch_contract_log_error';
export const FETCH_CONTRACT_LOG = 'fetch_contract_log';
export const REORDER_ITEMS = 'reorder_contract_items';
export const REORDER_PACKAGES = 'reorder_packages';
export const ADD_CONTRACT_ITEM = 'add_contract_item';
export const SOFT_DELETE_TEMPLATE = 'soft_delete_template';
export const COPY_TEMPLATE = 'copy_template';
export const DELETE_CONTRACT_ITEM = 'delete_contract_item';
export const DELETE_SECTION = 'delete_section';
export const DELETE_PACKAGE = 'delete_package';
export const TOGGLE_ITEM_ACTIVE = 'toggle_item_active';
export const REORDER_SECTIONS = 'reorder_sections';
export const CREATE_TEMPLATE = 'create_template';
export const CREATE_TEMPLATE_ERROR = 'create_template_error';
export const ADD_SECTION_TO_TEMPLATE = 'add_section_to_template';
export const ADD_SIGNER_SECTION_TO_TEMPLATE = 'add_signer_section_to_template';
export const ADD_PACKAGE_TO_SECTION = 'add_package_to_section';
export const ADD_ITEM_TO_SECTION = 'add_item_to_section';
export const ADD_ITEM_TO_PACKAGE = 'add_item_to_package';
export const CREATE_CONTRACT = 'create_contract';
export const CREATE_CONTRACT_ERROR = 'create_contract_error';
export const DELETE_CONTRACT = 'delete_contract';
export const DELETE_CONTRACT_ERROR = 'delete_contract_error';
export const UPDATE_EDITOR_STATE = 'update_editor_state';
export const LOAD_EDITOR_STATE = 'load_editor_state';
export const LOAD_CONTRACT_TO_BUILDER = 'load_contract_to_builder';
export const LOAD_TEMPLATE_TO_BUILDER = 'load_template_to_builder';
export const UPDATE_ITEM_OBJECT = 'update_item_object';
export const UPDATE_ITEM_ATTRIBUTE = 'update_item_attribute';
export const UPDATE_PACKAGE = 'update_package';
export const UPDATE_SECTION_OBJECT = 'update_section_object';
export const UPDATE_PACKAGE_ITEMS_REQUIRED = 'update_package_items_required';
export const UPDATE_SECTION_PERMISSION = 'update_section_permission';
export const UPDATE_CONTRACT_PERMISSION = 'update_contract_permission';
export const UPLOAD_ITEM_ICON = 'upload_item_icon';
export const DELETE_ITEM_ICON = 'delete_item_icon';
export const LOADING_ITEM_ICON = 'loading_item_icon';

// eslint-disable-next-line prettier/prettier
export const UPDATE_EXECUTE_CONTRACT_ITEM_OBJECT = 'update_execute_contract_item_object';

export const LOAD_ITEM_DETAILS = 'load_item_details';
export const EXECUTE_CONTRACT = 'execute_contract';
export const EXECUTE_CONTRACT_FAILED = 'execute_contract_failed';
export const REJECT_CONTRACT = 'reject_contract';
export const REJECT_CONTRACT_FAILED = 'reject_contract_failed';
export const ADD_SIGNATURE_TO_STATE = 'add_signature_to_state';
export const CONTRACT_SIGNED = 'contract_signed';
export const CONTRACT_NOT_SIGNED = 'contract_not_signed';
export const FINALIZE_CONTRACT = 'finalize_contract';
export const FINALIZE_CONTRACT_FAILED = 'finalize_contract_failed';

export const GET_CONTRACT_TOTALS = 'get_contract_totals';
export const CONTRACT_LOADING = 'contract_loading';
export const CONTRACT_LOADING_DONE = 'contract_loading_done';
export const CONTRACT_LOADING_FAILED = 'contract_loading_failed';
export const CONTRACT_LOADING_NOT_FOUND = 'contract_loading_not_found';
export const CONTRACT_AUTOSAVE_AWAITING = 'contract_autosave_awaiting';
export const CONTRACT_AUTOSAVE_STARTED = 'contract_autosave_started';
export const CONTRACT_AUTOSAVE_SUCCEEDED = 'contract_autosave_succeeded';
export const CONTRACT_AUTOSAVE_CANCELED = 'contract_autosave_canceled';
export const CONTRACT_AUTOSAVE_FAILED = 'contract_autosave_failed';

export const TEMPLATE_AUTOSAVE_SUCCEEDED = 'template_autosave_succeeded';

export const COPY_CONTRACT_SUCCEEDED = 'copy_contract_succeeded';
export const COPY_CONTRACT_FAILED = 'copy_contract_failed';

export const COPY_TEMPLATE_SUCCEEDED = 'copy_template_succeeded';
export const COPY_TEMPLATE_FAILED = 'copy_template_failed';

export const TEMPLATE_NAME_CHANGED = 'template_name_changed';
export const TEMPLATE_LOGO_CHANGED = 'template_logo_changed';

export const AUTH_USER = 'auth_user';
export const AUTH_VERIFIED = 'auth_verified';
export const AUTH_ERROR = 'auth_error';
export const AUTH_LOADING = 'auth_loading';
export const AUTH_GOOGLE = 'auth_google';
export const AUTH_SIGNOUT = 'auth_signout';

export const SHOW_NOTIFICATION = 'show_notification';
export const CLOSE_NOTIFICATION = 'close_notification';
export const ITEM_LOADING_UI = 'item_loading_ui';
export const FOCUS_PRODUCT = 'focus_product';
export const FOCUS_PRODUCT_ERROR = 'focus_product_error';
export const SHOW_GLOBAL_LOADER = 'show_global_loader';
export const HIDE_GLOBAL_LOADER = 'hide_global_loader';
export const FOCUS_ACTIVITY_TAB = 'focus_activity_tab';
export const ADD_CONTRACT_ITEM_LOADING = 'add_contract_item_loading';
export const ADD_CONTRACT_ITEM_LOADING_DONE = 'add_contract_item_loading_done';
export const ITEM_DELETE_LOADING = 'item_delete_loading';
export const ITEM_DELETE_LOADING_DONE = 'item_delete_loading_done';
export const RESET_BUILDER_UI = 'reset_builder_ui';
export const NOTIFY_PARTIES_DONE = 'notify_parties_done';
export const NOTIFY_PARTIES_ERROR = 'notify_parties_error';
export const EXECUTED_PDF_LOADING = 'executed_pdf_loading';
export const EXECUTED_PDF_GENERATING = 'executed_pdf_generating';
export const EXECUTED_PDF_GENERATING_DONE = 'executed_pdf_generating_done';
export const EXECUTED_PDF_GENERATING_ERROR = 'executed_pdf_generating_error';

export const LOAD_CONTRACT_TIME_DATA = 'load_contract_time_data';

export const FETCH_USERGROUPS_STARTED = 'fetch_usergroups_started';
export const FETCH_USERGROUPS = 'fetch_usergroups';
export const FETCH_USERGROUPS_ERROR = 'fetch_usergroups_error';
export const FETCH_USERGROUPS_FINISHED = 'fetch_usergroups_finished';

export const CREATING_NEW_USER = 'creating_new_user';
export const CREATE_NEW_USER_FINISHED = 'create_new_user_finished';
export const CREATE_NEW_USER_ERROR = 'create_new_user_error';
export const UPDATING_USER = 'updating_user';
export const UPDATING_USER_DONE = 'updating_user_done';
export const UPDATE_USER_FINISHED = 'update_user_finished';
export const UPDATE_USER_ERROR = 'update_user_error';
export const DELETE_USER_FINISHED = 'delete_user_finished';
export const DELETE_USER_ERROR = 'delete_user_error';
export const FETCH_USERS_DENIED = 'fetch_users_denied';

export const USER_FEEDBACK_SENT = 'user_feedback_sent';
export const SHOW_FEEDBACK_MODAL = 'show_feedback_modal';
