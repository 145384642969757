/* eslint-disable array-callback-return */
import * as types from '../actions/types';

const INITIAL_STATE = {};

function getFormattedSections(data) {
  const sections = data.itemSections ? data.itemSections : [];
  const newSections = {};
  for (let i = 0; i < sections.length; i++) {
    newSections[`section-${sections[i].id}`] = sections[i];
  }

  const sectionOrder = data.sectionOrder ? data.sectionOrder : [];
  const newSectionOrder = [];
  for (let i = 0; i < sectionOrder.length; i++) {
    newSectionOrder[i] = `section-${sectionOrder[i]}`;
  }

  return [newSections, newSectionOrder];
}

function getFormattedPackages(data) {
  const packages = data.packages ? data.packages : [];
  const newPackages = {};
  for (let i = 0; i < packages.length; i++) {
    newPackages[`package-${packages[i].id}`] = packages[i];
  }
  return newPackages;
}

function getFormattedItems(data) {
  const itemsData = data.items ? data.items : [];
  const newItems = {};
  for (let i = 0; i < itemsData.length; i++) {
    newItems[`item-${itemsData[i].id}`] = itemsData[i];
    newItems[`item-${itemsData[i].id}`].itemId = `item-${itemsData[i].id}`;

    if (itemsData[i].type === 'customText' && data.itemLargeTexts) {
      // 'items do not contain itemLargeText data, grabbing itemLargeText data from api response and then converting draftEditorRawState'

      data.itemLargeTexts.map(ilt => {
        if (ilt.item === itemsData[i].id) {
          newItems[`item-${itemsData[i].id}`].itemLargeText = [ilt];
        }
      });
    }
  }

  return newItems;
}

function getFormattedContractRoles(data) {
  const contractRoleData = data.contractRoles ? data.contractRoles : [];
  const newContractRoleData = {};
  if (contractRoleData) {
    for (let i = 0; i < contractRoleData.length; i++) {
      newContractRoleData[contractRoleData[i].id] = contractRoleData[i];
    }
  }

  return newContractRoleData;
}

function formatApiResponseToState(data) {
  const formattedData = { ...data };

  const [newSections, newSectionOrder] = getFormattedSections(data);

  const newPackages = getFormattedPackages(data);

  const newItems = getFormattedItems(data);

  const newContractRoleData = getFormattedContractRoles(data);

  formattedData.contractId = data.id;
  formattedData.sections = newSections;
  formattedData.sectionOrder = newSectionOrder;
  formattedData.packages = newPackages;
  formattedData.items = newItems;
  formattedData.contractRoles = newContractRoleData;

  return formattedData;
}

export default function(state = INITIAL_STATE, action) {
  // console.log('action: ', action);

  switch (action.type) {
    case types.EXECUTE_CONTRACT: {
      return {
        ...state,
        isExecuted: true,
        executedDatetime: action.payload.success.executedDatetime,
      };
    }

    case types.EXECUTE_CONTRACT_FAILED:
      return {
        ...state,
        isExecuted: false,
      };

    case types.REJECT_CONTRACT:
      return {
        ...state,
        isRejected: true,
      };

    case types.REJECT_CONTRACT_FAILED:
      return {
        ...state,
        isRejected: true,
      };

    case types.CREATE_CONTRACT_ERROR:
      return { ...state, errorMessage: action.payload };

    case types.CREATE_TEMPLATE:
      const createdTemplate = formatApiResponseToState(action.payload);
      return {
        ...state,
        errorMessage: '',
        ...createdTemplate,
      };

    case types.GET_CONTRACT_TOTALS:
      const { contractTotals } = action.payload.response;
      return {
        ...state,
        contractTotals,
      };

    case types.CREATE_CONTRACT:
      const createdContract = formatApiResponseToState(action.payload);
      return {
        ...state,
        errorMessage: '',
        ...createdContract,
      };

    case types.LOAD_TEMPLATE_TO_BUILDER:
      const templateToLoad = formatApiResponseToState(action.payload.contract);
      return {
        ...state,
        signerUser: action.payload.signerUser,
        errorMessage: '',
        ...templateToLoad,
      };

    case types.LOAD_CONTRACT_TO_BUILDER:
      const contractToLoad = formatApiResponseToState(action.payload.contract);
      return {
        ...state,
        signerUser: action.payload.signerUser,
        errorMessage: '',
        ...contractToLoad,
      };

    // todo: there may be an oportunity to combine this with types.LOAD_CONTRACT_TO_BUILDER
    case types.LOAD_CONTRACT_TO_STATE:
      const contract = formatApiResponseToState(action.payload.contract);

      contract.isExpired = false;

      if (contract.finalizedDatetime) {
        // 90 days
        const expirationDate =
          new Date(contract.finalizedDatetime).getTime() + 90 * 86400000;
        const now = new Date().getTime();

        if (now > expirationDate) contract.isExpired = true;
      }

      return {
        ...state,
        errorMessage: '',
        createdByUser: action.payload.createdByUser,
        signerUser: action.payload.signerUser,
        billing: action.payload.billing,
        ...contract,
      };

    case types.ADD_CONTRACT_ITEM: {
      const newItem = action.payload;
      newItem.itemId = `item-${newItem.id}`;
      const sectionId = `section-${newItem.itemSection}`;

      return {
        ...state,
        items: {
          ...state.items,
          [newItem.itemId]: newItem,
        },
        sections: {
          ...state.sections,
          [sectionId]: {
            ...state.sections[sectionId],
            itemOrder: [...state.sections[sectionId].itemOrder, newItem.id],
          },
        },
      };
    }

    case types.DELETE_SECTION: {
      const { deletedSections } = action.payload;
      const sectionId = `section-${deletedSections[0].id}`;
      const newSections = JSON.parse(JSON.stringify(state.sections));
      delete newSections[sectionId];
      const newSectionOrder = state.sectionOrder.filter(id => id !== sectionId);
      return { ...state, sections: newSections, sectionOrder: newSectionOrder };
    }

    case types.DELETE_PACKAGE: {
      const { deletedPackage } = action.payload;
      const packageId = `package-${deletedPackage[0].id}`;
      const newPackages = JSON.parse(JSON.stringify(state.packages));
      delete newPackages[packageId];
      const sectionId = `section-${deletedPackage[0].itemSection}`;
      const newSection = JSON.parse(JSON.stringify(state.sections[sectionId]));
      newSection.packageOrder = newSection.packageOrder.filter(
        id => id !== deletedPackage[0].id
      );
      const newSections = JSON.parse(JSON.stringify(state.sections));
      newSections[sectionId] = newSection;
      return { ...state, packages: newPackages, sections: newSections };
    }

    case types.DELETE_CONTRACT_ITEM: {
      const { itemId } = action.payload;

      const packageId = state.items[`item-${itemId}`].package;

      const itemKey = `item-${itemId}`;
      const remainingItems = Object.keys(state.items)
        .filter(key => key !== itemKey)
        .reduce((result, current) => {
          result[current] = state.items[current];
          return result;
        }, {});

      const newItemOrder = state.packages[
        `package-${packageId}`
      ].itemOrder.filter(id => id !== itemId);

      return {
        ...state,
        items: remainingItems,
        packages: {
          ...state.packages,
          [`package-${packageId}`]: {
            ...state.packages[`package-${packageId}`],
            itemOrder: newItemOrder,
          },
        },
      };
    }

    // /******* reorder packages ************/
    case types.REORDER_PACKAGES: {
      const { destination, source, draggableId } = action.payload;

      const sourceDropId = source.droppableId;
      const destinationDropId = destination.droppableId;

      const sourceSection = state.sections[sourceDropId];
      const destinationSection = state.sections[destinationDropId];

      if (sourceSection === destinationSection) {
        const newPackageOrder = [...sourceSection.packageOrder];
        newPackageOrder.splice(source.index, 1);
        newPackageOrder.splice(
          destination.index,
          0,
          sourceSection.packageOrder[source.index]
        );

        const newSection = { ...sourceSection, packageOrder: newPackageOrder };
        const newSections = {
          ...state.sections,
          [`section-${newSection.id}`]: newSection,
        };
        return {
          ...state,
          sections: newSections,
          errorMessage: '',
        };
      }

      // moving from one section to another
      const sourcePackageOrder = [...sourceSection.packageOrder];

      // remove the itemId from the source section list of item ids
      sourcePackageOrder.splice(source.index, 1);

      // create new section with updated packageOrder list
      const newSourceSection = {
        ...sourceSection,
        packageOrder: sourcePackageOrder,
      };

      // add the itemID to the destination section list of item ids
      const destinationPackageOrder = [...destinationSection.packageOrder];
      destinationPackageOrder.splice(
        destination.index,
        0,
        sourceSection.packageOrder[source.index]
      );

      // create new section with updated packageOrder list
      const newDestinationSection = {
        ...destinationSection,
        packageOrder: destinationPackageOrder,
      };

      //* * update package */

      const newPackage = state.packages[draggableId];
      newPackage.itemSection = parseInt(
        destination.droppableId.replace('section-', ''),
        10
      );

      return {
        ...state,
        sections: {
          ...state.sections,
          [`section-${newSourceSection.id}`]: newSourceSection,
          [`section-${newDestinationSection.id}`]: newDestinationSection,
        },
        packages: {
          ...state.packages,
          [`package-${draggableId}`]: newPackage,
        },
      };
    }
    // /********* end reorder packages **********/

    case types.REORDER_ITEMS: {
      const { destination, draggableId, source } = action.payload;

      const sourceDropId = source.droppableId;
      const destinationDropId = destination.droppableId;

      const sourcePackage = state.packages[sourceDropId];
      const destinationPackage = state.packages[destinationDropId];

      if (sourcePackage === destinationPackage) {
        const newItemOrder = [...sourcePackage.itemOrder];
        newItemOrder.splice(source.index, 1); // remove drag item from current postiong
        newItemOrder.splice(
          destination.index,
          0,
          sourcePackage.itemOrder[source.index]
        ); // add drag item to destination index

        const newPackage = { ...sourcePackage, itemOrder: newItemOrder };

        const newPackages = {
          ...state.packages,
          [`package-${newPackage.id}`]: Object.assign(
            state.packages[`package-${newPackage.id}`],
            newPackage
          ),
        };

        return {
          ...state,
          packages: newPackages,
          errorMessage: '',
        };
      }

      // moving from one section to another
      const sourceItemOrder = [...sourcePackage.itemOrder];

      // remove the itemId from the source package list of item ids
      sourceItemOrder.splice(source.index, 1);

      // create new section with updated itemOrder list
      const newsourcePackage = {
        ...sourcePackage,
        itemOrder: sourceItemOrder,
      };

      // add the itemID to the destination section list of item ids
      const destinationItemOrder = [...destinationPackage.itemOrder];
      destinationItemOrder.splice(
        destination.index,
        0,
        sourcePackage.itemOrder[source.index]
      );

      // create new package with updated itemOrder list
      const newdestinationPackage = {
        ...destinationPackage,
        itemOrder: destinationItemOrder,
      };

      // TODO: need to update items assoction to packages
      const updatedItem = state.items[draggableId];
      updatedItem.package = destinationPackage.id;

      return {
        ...state,
        packages: {
          ...state.packages,
          [`package-${newsourcePackage.id}`]: Object.assign(
            state.packages[`package-${newsourcePackage.id}`],
            newsourcePackage
          ),
          [`package-${newdestinationPackage.id}`]: Object.assign(
            state.packages[`package-${newdestinationPackage.id}`],
            newdestinationPackage
          ),
        },
        items: {
          ...state.items,
          [draggableId]: Object.assign(state.items[draggableId], updatedItem),
        },
      };
    }
    case types.REORDER_SECTIONS:
      const sectionSource = action.payload.source;
      const sectionDestination = action.payload.destination;

      const newSectionOrder = [...state.sectionOrder];
      newSectionOrder.splice(sectionSource.index, 1);
      newSectionOrder.splice(
        sectionDestination.index,
        0,
        state.sectionOrder[sectionSource.index]
      );
      return {
        ...state,
        sectionOrder: newSectionOrder,
      };

    case types.LOAD_ITEM_DETAILS:
      const newItemDetails = action.payload;
      newItemDetails.active = true; // set item to active in case server hasn't been updated by the time we load newItemDetails
      const newItemId = `item-${newItemDetails.id}`;

      return {
        ...state,
        items: {
          ...state.items,
          [newItemId]: {
            ...state.items[newItemId],
            ...newItemDetails,
          },
        },
      };

    case types.TOGGLE_ITEM_ACTIVE:
      const itemId = action.payload;
      const newItem = state.items[itemId];

      return {
        ...state,
        items: {
          ...state.items,
          [itemId]: {
            ...state.items[itemId],
            active: !newItem.active,
          },
        },
      };

    case types.UPDATE_ITEM_OBJECT: {
      const { itemId } = action.payload;
      const attributeToUpdate = action.payload.attribute;
      const objectToMerge = action.payload.body;
      const itemToUpdate = JSON.parse(JSON.stringify(state.items[itemId]));

      const newAttributeObject = Object.assign(
        itemToUpdate[attributeToUpdate],
        objectToMerge
      );

      const newItem = {
        ...itemToUpdate,
        attributeToUpdate: newAttributeObject,
      };

      return {
        ...state,
        items: {
          ...state.items,
          [itemId]: newItem,
        },
      };
    }

    case types.UPDATE_ITEM_ATTRIBUTE: {
      const { id, attribute, body } = action.payload;
      const itemId = `item-${id}`;

      return {
        ...state,
        items: {
          ...state.items,
          [itemId]: {
            ...state.items[itemId],
            [attribute]: body[attribute],
          },
        },
      };
    }

    case types.UPDATE_PACKAGE: {
      const { updatedPackage } = action.payload;
      const packageId = `package-${updatedPackage.id}`;

      return {
        ...state,
        packages: {
          ...state.packages,
          [packageId]: updatedPackage,
        },
      };
    }

    case types.UPDATE_SECTION_OBJECT: {
      const { id, attribute, body } = action.payload;
      const sectionId = `section-${id}`;

      return {
        ...state,
        sections: {
          ...state.sections,
          [sectionId]: {
            ...state.sections[sectionId],
            [attribute]: body[attribute],
          },
        },
      };
    }

    case types.UPDATE_EDITOR_STATE: {
      const { itemId } = action.payload;

      const itemLargeText = state.items[itemId].itemLargeText.map(itemText => ({
        ...itemText,
        html: action.payload._editorState,
      }));

      return {
        ...state,
        items: {
          ...state.items,
          [itemId]: {
            ...state.items[itemId],
            itemLargeText,
            // _editorState: action.payload._editorState, // TODO: I don't think anyone is using this, but just in case
          },
        },
      };
    }

    case types.ADD_SIGNATURE_TO_STATE: {
      const { item, img } = action.payload;

      const signatureObject = {
        signatureImage: img,
      };

      const itemToModify = state.items[item.itemId];

      const newItem = Object.assign(itemToModify, signatureObject);

      const newItems = {
        ...state.items,
        [item.itemId]: newItem,
      };

      return {
        ...state,
        items: newItems,
      };
    }

    case types.CONTRACT_SIGNED: {
      return {
        ...state,
        _contractSigned: true,
      };
    }

    case types.CONTRACT_NOT_SIGNED: {
      return {
        ...state,
        _contractSigned: false,
      };
    }

    case types.FETCH_CONTRACT_LOG: {
      const contractLogResult = action.payload.data;
      return { ...state, contractLogResult };
    }

    case types.ADD_SECTION_TO_TEMPLATE: {
      const { newItemSection, newSectionOrder } = action.payload;
      const newSectionKey = `section-${newItemSection.id}`;
      const formattedSectionOrder = newSectionOrder.map(
        section => `section-${section}`
      );
      return {
        ...state,
        sections: {
          ...state.sections,
          [newSectionKey]: newItemSection,
        },
        sectionOrder: formattedSectionOrder,
      };
    }

    case types.ADD_PACKAGE_TO_SECTION: {
      const { newPackage, newPackageOrder } = action.payload;
      const newPackageKey = `package-${newPackage.id}`;
      // const formattedPackageOrder = newPackageOrder.map(
      //   section => `package-${section}`
      // );

      const sectionKey = `section-${newPackage.itemSection}`;

      return {
        ...state,
        packages: {
          ...state.packages,
          [newPackageKey]: newPackage,
        },
        sections: {
          ...state.sections,
          [sectionKey]: {
            ...state.sections[sectionKey],
            packageOrder: newPackageOrder,
          },
        },
      };
    }

    case types.ADD_ITEM_TO_SECTION: {
      const { newSection, newPackage, newItem } = action.payload;
      const itemKey = `item-${newItem.id}`;
      const packageKey = `package-${newPackage.id}`;
      const sectionKey = `section-${newSection.id}`;

      newItem.itemId = itemKey;
      if (newItem.type === 'customText') {
        const itemLargeText = JSON.parse(
          JSON.stringify(newItem.itemLargeText[0])
        );
        newItem.itemLargeText = [itemLargeText];
      }

      const newItems = {
        ...state.items,
        [itemKey]: newItem,
      };

      const newPackages = {
        ...state.packages,
        [packageKey]: newPackage,
      };

      const newSections = {
        ...state.sections,
        [sectionKey]: Object.assign(state.sections[sectionKey], newSection),
      };

      return {
        ...state,
        items: newItems,
        packages: newPackages,
        sections: newSections,
      };
    }

    case types.ADD_ITEM_TO_PACKAGE: {
      const { newPackage, newItem } = action.payload;
      const newItemKey = `item-${newItem.id}`;
      const packageKey = `package-${newPackage.id}`;

      newItem.itemId = newItemKey;
      if (newItem.type === 'customText') {
        const itemLargeText = JSON.parse(
          JSON.stringify(newItem.itemLargeText[0])
        );
        newItem.itemLargeText = [itemLargeText];
      }

      const newItems = {
        ...state.items,
        [newItemKey]: newItem,
      };

      return {
        ...state,
        items: newItems,
        packages: {
          ...state.packages,
          [packageKey]: {
            ...state.packages[packageKey],
            itemOrder: newPackage.itemOrder,
          },
        },
      };
    }

    case types.ADD_SIGNER_SECTION_TO_TEMPLATE: {
      const {
        newSectionOrder,
        newItemSection,
        newPackage,
        newItemsArray,
      } = action.payload;

      const formattedSectionOrder = newSectionOrder.map(
        section => `section-${section}`
      );

      const newSectionKey = `section-${newItemSection.id}`;
      const packageKey = `package-${newPackage.id}`;

      const newSections = {
        ...state.sections,
        [newSectionKey]: newItemSection,
      };

      const newPackages = {
        ...state.packages,
        [packageKey]: newPackage,
      };

      const formattedItemsObject = newItemsArray.reduce((obj, item) => {
        obj[`item-${item.id}`] = item;
        return obj;
      }, {});

      const newItems = {
        ...state.items,
        ...formattedItemsObject,
      };

      return {
        ...state,
        sectionOrder: formattedSectionOrder,
        sections: newSections,
        packages: newPackages,
        items: newItems,
      };
    }

    case types.FINALIZE_CONTRACT: {
      return {
        ...state,
        finalizedLoading: false,
        finalized: true,
        // This is not the real finalizedDateTime, but the component need it to be greater than 0
        finalizedDatetime: new Date().getTime(),
        token: action.payload.token,
        user: action.payload.user,
      };
    }

    case types.FINALIZE_CONTRACT_FAILED: {
      return {
        ...state,
        finalizedLoading: false,
      };
    }

    case types.EXECUTED_PDF_GENERATING_DONE: {
      const { uploads } = action.payload;
      return { ...state, executedContractPdf: uploads.key };
    }

    case types.UPDATE_PACKAGE_ITEMS_REQUIRED: {
      const { updatedPackage } = action.payload;
      const items = updatedPackage.itemOrder;

      const newItems = {};
      items.map(item => {
        const notRequiredItemId = `item-${item}`;
        const itemToUpdate = state.items[notRequiredItemId];

        const notRequiredItem = {
          ...itemToUpdate,
          required: false,
        };

        newItems[notRequiredItemId] = notRequiredItem;
      });

      return {
        ...state,
        items: {
          ...state.items,
          ...newItems,
        },
      };
    }

    case types.TEMPLATE_NAME_CHANGED: {
      const template = action.payload;

      return { ...state, name: template.name };
    }

    case types.TEMPLATE_LOGO_CHANGED: {
      const template = action.payload;

      return { ...state, customLogo: template.customLogo };
    }

    case types.UPDATE_EXECUTE_CONTRACT_ITEM_OBJECT: {
      const { itemId } = action.payload;
      const attributeToUpdate = action.payload.attribute;
      const objectToMerge = action.payload.body;
      const itemToUpdate = JSON.parse(JSON.stringify(state.items[itemId]));

      const newAttributeObject = Object.assign(
        itemToUpdate[attributeToUpdate],
        objectToMerge
      );

      const newItem = {
        ...itemToUpdate,
        attributeToUpdate: newAttributeObject,
      };

      return {
        ...state,
        items: {
          ...state.items,
          [itemId]: newItem,
        },
      };
    }
    case types.LOADING_ITEM_ICON: {
      const { itemId } = action.payload;

      const item = state.items[`item-${itemId}`];
      item.iconLoading = true;

      const items = {
        ...state.items,
        item,
      };

      return { ...state, items };
    }

    case types.DELETE_ITEM_ICON: {
      const { itemId } = action.payload;

      const item = state.items[`item-${itemId}`];
      item.iconLoading = false;
      item.itemUpload = [];
      item.newIcon = false;

      const items = {
        ...state.items,
        item,
      };

      return { ...state, items };
    }

    case types.UPLOAD_ITEM_ICON: {
      const { itemId, itemUpload } = action.payload;

      const item = state.items[`item-${itemId}`];

      if (!item.itemUpload) item.itemUpload = [];

      item.itemUpload.push(itemUpload);
      item.newIcon = true; // Update the icon on product list

      const items = {
        ...state.items,
        item,
      };

      return { ...state, items };
    }
    default:
      return state;
  }
}
