import { Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as actions from '../../actions';
import Can from '../Can';
import ContractCreateModal from '../contract/ContractCreateModal';
import ContractExecuteReport from '../contract/ContractExecuteReport';
import ContractStatReport from '../contract/ContractStatReport';
import DashboardFilters from '../dashboard/DashboardFilters';
import { InnerNav, MainTitle } from '../styledComponents';

const DashboardInnerNav = styled(InnerNav)`
  grid-template-columns: auto 1fr auto;
  margin-bottom: 0;
  padding-left: 20px;
`;

const DashboardSectionHeader = styled(InnerNav)`
  grid-template-columns: auto 1fr auto;
  margin-bottom: 0;
  padding-left: 20px;
  border-bottom: none;
  margin-top: 20px;
`;

class DashboardIndex extends React.Component {
  componentDidMount() {
    // this.props.fetchTemplates();
    this.props.fetchContractTimeReport();
  }

  render() {
    return (
      <div className="main-component">
        <MainTitle>Dashboard</MainTitle>
        <Can
          role={this.props.auth.user.role}
          perform="contract:create"
          yes={() => (
            <React.Fragment>
              <DashboardInnerNav className="bg-white">
                <img
                  src="https://d3gqnlyjfmjmla.cloudfront.net/icons/m360-icon35-check.png"
                  alt=""
                />
                <h3>Create New Contract</h3>
                <ContractCreateModal />
              </DashboardInnerNav>
            </React.Fragment>
          )}
        />
        <Row>
          <DashboardSectionHeader className="bg-white">
            <img
              src="https://d3gqnlyjfmjmla.cloudfront.net/icons/m360-icon35-chart.png"
              alt=""
            />
            <h3>Reports</h3>
            <DashboardFilters auth={this.props.auth} />
          </DashboardSectionHeader>
          <ContractExecuteReport
            auth={this.props.auth}
            timeChart={this.props.timeChart}
          />
        </Row>

        <Row>
          <DashboardSectionHeader className="bg-white">
            <img
              src="https://d3gqnlyjfmjmla.cloudfront.net/icons/m360-icon35-user.png"
              alt=""
            />
            <h3>User Report</h3>
          </DashboardSectionHeader>
          <ContractStatReport circleGraphs={this.props.circleGraphs} />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  search: state.search,
  contract: state.contract.present,
  auth: state.auth,
  timeChart: state.report.timeChart,
  circleGraphs: state.report.circleGraphs,
});

export default connect(mapStateToProps, actions)(DashboardIndex);
