import React from 'react'
import styled from 'styled-components'

const Action = styled.div`
  padding: 10px;
  border: 1px solid #efefef;
  margin: 5px 0px 0px;
`

export default function ActionLog() {
  return (
    <Action>
      <p>Fake action logged</p>
    </Action>
  )
}
