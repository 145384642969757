/**
 * Role-Based Access Control
 *
 * Inspired by: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
 */

const rules = {
  client: {
    static: [],
  },
  consultant: {
    static: [
      'contracts-index:view',
      'contract:create',
      'contract:clone',
      'contract:edit',
      'contract:preview',
      'contract:view',
      'contract:copy',
      'contract:reject',
      'contract:log',
      'contract:getlink',
      'contract:downloadPdf',

      'dashboard-index:view',
      'contracts-index:view',
    ],
  },
  torstar_consultant: {
    static: [
      'contracts-index:view',
      'contract:create',
      'contract:clone',
      'contract:edit',
      'contract:preview',
      'contract:view',
      'contract:copy',
      'contract:reject',
      'contract:log',
      'contract:getlink',
      'contract:downloadPdf',

      'dashboard-index:view',
      'contracts-index:view',
    ],
  },
  ag_consultant: {
    static: [
      'contracts-index:view',
      'contract:create',
      'contract:clone',
      'contract:edit',
      'contract:preview',
      'contract:view',
      'contract:copy',
      'contract:reject',
      'contract:log',
      'contract:getlink',
      'contract:downloadPdf',

      'dashboard-index:view',
      'contracts-index:view',
    ],
  },

  // The admin role contains ALL the permissions available
  // in this application. Even if it is never used, keep it
  // here as a reference and template for the other roles.
  admin: {
    static: [
      'contracts-index:view',
      'contract:create',
      'contract:clone',
      'contract:edit',
      'contract:preview',
      'contract:view',
      'contract:copy',
      'contract:reject',
      'contract:log',
      'contract:getlink',
      'contract:downloadPdf',
      'contract:delete',

      'templates-index:view',
      'template:create',
      'template:edit',
      'template:view',
      'template:delete',

      'users-index:view',
      'user:create',
      'user:edit',
      'user:view',
      'user:delete',

      'dashboard-index:view',
      'settings-index:view',
      'reports-index:view',
    ],
  },
  director: {
    static: [
      'contracts-index:view',
      'contract:preview',
      'contract:view',
      'contract:log',
      'contract:getlink',
      'contract:downloadPdf',
      'contract:create',
      'contract:clone',
      'contract:edit',
      'contract:copy',
      'contract:reject',

      'dashboard-index:view',

      'contracts-index:view',
    ],

  },
};

export default rules;
