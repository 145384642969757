import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import undoable from 'redux-undo';
import searchReducer from './search';
import authReducer from './auth';
import contractBuilderReducer from './contract';
import uiReducer from './ui';
import reportReducer from './report';
import userReducer from './user';

// Prevents large, nested history tree
// Added (11/15/2018)
// Found at: https://github.com/omnidan/redux-undo/issues/211
// Should be fixed in a later build of redux-undo
function patchHistoryBloat(reducer) {
  return (state, action, ...rest) => {
    const res = reducer(state, action, ...rest);
    if (res.history && res.history.history) {
      delete res.history.history;
    }
    return res;
  };
}

export default combineReducers({
  form: formReducer,
  search: searchReducer,
  auth: authReducer,
  report: reportReducer,
  contract: patchHistoryBloat(
    undoable(contractBuilderReducer, {
      limit: 20, // set a limit for the history
    })
  ),
  ui: uiReducer,
  users: userReducer,
});
