import * as types from '../actions/types';

function defaultDateSet() {
  return {
    pointStyle: 'circle',
    position: 'right',
    cubicInterpolationMode: 'default', // default,monotone
    fill: false,
    lineTension: 0.1,
    borderCapStyle: 'round',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 2,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
    pointRadius: 3,
    pointHitRadius: 1,
    showXLabels: 10,
    data: [0],
  };
}

const INITIAL_STATE = {
  timeChart: {
    contractsCreatedVsExecuted: {
      data: [],
      loading: true,
      labels: ['x'],
      datasets: [
        {
          label: 'contracts',
          ...defaultDateSet(),
          data: [0],
        },
      ],
    },
  },
  circleGraphs: {
    winPercentage: {
      title: {
        text: 0,
      },
      subTitle: {
        text: 'Win %',
      },
      labels: ['Executed Contracts', 'Pending Contracts'],
      datasets: [
        {
          data: [0, 100],
          backgroundColor: ['#8dc1e3', '#DCDCDC'],
          hoverBackgroundColor: ['#8dc1e3', '#DCDCDC'],
        },
      ],
      loading: true,
    },
    contractsFinalized: {
      title: {
        text: '',
      },
      subTitle: {
        text: 'Finalized Contracts',
      },
      labels: ['Executed Contracts', 'Pending Contracts', 'Rejected Contracts'],
      datasets: [
        {
          data: [0, 0, 0],
          backgroundColor: ['#3895D3', '#20669e', '#8dc1e3'],
          hoverBackgroundColor: ['#3895D3', '#20669e', '#8dc1e3'],
        },
      ],
      loading: true,
    },
  },
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.LOAD_CONTRACT_TIME_DATA: {
      // console.log('payload: ', action.payload);
      let {
        contractsDailyCount,
        executedContractsCount,
        pendingContractsCount,
        rejectedContractsCount,
        winPercentage,
        templatesAvailable,
      } = action.payload;

      const labels = [];
      const created = [];
      const executed = [];
      const data = [];

      if (!winPercentage) winPercentage = 0;
      if (!executedContractsCount) executedContractsCount = 0;
      if (!pendingContractsCount) pendingContractsCount = 0;
      if (!rejectedContractsCount) rejectedContractsCount = 0;

      if (contractsDailyCount) {
        for (let i = 0; i < contractsDailyCount.created.length; i++) {
          labels[i] = contractsDailyCount.created[i].date;
          created[i] = contractsDailyCount.created[i].count;
          data.push({
            name: contractsDailyCount.created[i].date,
            created: contractsDailyCount.created[i].count,
          });
        }
      }

      if (contractsDailyCount) {
        for (let i = 0; i < contractsDailyCount.executed.length; i++) {
          data[i] = {
            ...data[i],
            executed: contractsDailyCount.executed[i].count,
          };
          executed[i] = contractsDailyCount.executed[i].count;
        }
      }

      const finalizedContractsCount =
        executedContractsCount + pendingContractsCount + rejectedContractsCount;

      let winPercentageColors = ['#6BAED6', '#F2F3F5'];
      if (winPercentage === 100) {
        winPercentageColors = ['#3895D3', '#F2F3F5'];
      }

      const newState = {
        ...state,
        timeChart: {
          contractsCreatedVsExecuted: {
            loading: false,
            data,
            labels,
            datasets: [
              {
                label: 'Contracts Created',
                ...defaultDateSet(),
                backgroundColor: 'rgb(220,220,220,1)',
                borderColor: 'rgb(220,220,220,1)',
                pointBorderColor: 'rgb(220,220,220,1)',
                pointHoverBackgroundColor: 'rgb(220,220,220,1)',
                pointHoverBorderColor: 'rgb(220,220,220,1)',
                data: created,
              },
              {
                label: 'Contracts Executed',
                ...defaultDateSet(),
                backgroundColor: 'rgb(56, 149, 211,1)',
                borderColor: 'rgb(56, 149, 211,1)',
                pointBorderColor: 'rgb(56, 149, 211,1)',
                pointHoverBackgroundColor: 'rgb(56, 149, 211,1)',
                pointHoverBorderColor: 'rgb(56, 149, 211,1)',
                data: executed,
              },
            ],
          },
        },
        circleGraphs: {
          winPercentage: {
            title: {
              text: winPercentage,
            },
            subTitle: {
              text: 'Win %',
            },
            labels: ['Executed Contracts', 'Pending Contracts'],
            loading: false,
            data: [
              {
                name: 'Executed Contracts',
                value: winPercentage,
                fill: winPercentageColors[0],
              },
              {
                name: 'Pending Contracts',
                value: 100 - winPercentage,
                fill: winPercentageColors[1],
              },
            ],
          },
          contractsFinalized: {
            title: {
              text: finalizedContractsCount,
            },
            subTitle: {
              text: 'Finalized Contracts',
            },
            labels: [
              'Executed Contracts',
              'Pending Contracts',
              'Rejected Contracts',
            ],
            datasets: [
              {
                data: [
                  executedContractsCount,
                  pendingContractsCount,
                  rejectedContractsCount,
                ],
                backgroundColor: ['#3895D3', '#20669e', '#8dc1e3'],
                hoverBackgroundColor: ['#3895D3', '#20669e', '#8dc1e3'],
              },
            ],
            data: [
              {
                name: 'Executed Contracts',
                value: executedContractsCount,
                fill: '#3895D3',
              },
              {
                name: 'Pending Contracts',
                value: pendingContractsCount,
                fill: '#20669e',
              },
              {
                name: 'Rejected Contracts',
                value: rejectedContractsCount,
                fill: '#8dc1e3',
              },
            ],
            loading: false,
          },
          contractsTemplates: {
            loading: false,
            count: templatesAvailable,
          },
        },
      };

      console.log(newState);

      return newState;
    }
    default:
      return state;
  }
}
