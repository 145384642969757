import 'antd/dist/antd.css';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import asyncComponent from './AsyncComponent';
import routes from './routes';

const AsyncAppSkeleton = asyncComponent(() => import('./AppSkeleton'));

const AsyncExecute = asyncComponent(() =>
  import('./contract/finalized/FinalizedContractExecute')
);

const AsyncAccount = asyncComponent(() =>
  import('./contract/finalized/FinalizedContractAccount')
);

const AsyncPayment = asyncComponent(() =>
  import('./contract/finalized/FinalizedContractPayment')
);

const AsyncLogin = asyncComponent(() => import('./authenticate/Login'));
const AsyncLoginRedirect = asyncComponent(() =>
  import('./authenticate/LoginRedirect')
);

const theme = {
  green: '#A0E368',
  blue: '#2191CE',
  darkblue: '#032B43',
  lightblue: '#78D5D7',

  lightgrey: '#f8f9fb',
  grey: '#efefef',
  mediumdarkgrey: '#dfdfdf',
  darkgrey: '#bfbfbf',

  darkergrey: '#8f8f8f',

  bordergrey: '#efeff0',
  backgroundgrey: '#f4f4f4',
  white: '#fff',
  text: '#222',

  yellow: '#F8B150',
  red: '#F25D5D',
  orange: '#F18F01',
  pink: '#A23B72',
};

const GlobalStyle = createGlobalStyle`
  html,body {
    height:100%;
  }
  body {
    color: ${theme.text} !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 16px !important;
  }
  strong {
    font-weight: 600 !important;
  }
  .bg-white{
    background-color: ${theme.white};
  }
  .bg-grey{
      background-color: ${theme.backgroundgrey};
  }
  a{
    text-decoration: none;
  }
  a:hover, button:hover {
      cursor: pointer;
      text-decoration: none;
  }
  a:active, a:focus, button:active, button:focus {
      outline: none;
      text-decoration: none !important;
  }
  .responsive {
    width: 100%;
    height: auto;
  }
  .flex{
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  }
  .horizontal-align {
    display: flex;
    align-items: center;
  }

  .center {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .bottom-0{
    margin-bottom: 0;
  }

  .top-20{
    margin-top: 20px;
  }

  .text-left{
    text-align: left;
  }

  .main-component {
    width: 100%;
    /* max-width: 1200px; */
  }

  .drag-box{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    background-color: ${theme.white};
    align-items: center;
    padding-left: 10px;
    margin-bottom: 10px;
  }

  .drag-item, .drag-package{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 10px;
    border: 1px solid ${theme.bordergrey};
  }

  .custom-input{
    cursor: pointer;
  }
`;

class App extends Component {
  render() {
    return (
      <>
        <Router>
          <ThemeProvider theme={theme}>
            <Switch>
              <Route
                path="/signin"
                component={AsyncLogin}
                props={this.props}
                exact
              />
              <Route
                path="/signin/redirect"
                component={AsyncLoginRedirect}
                props={this.props}
              />
              <Route
                path="/contracts/execute/:token"
                component={AsyncExecute}
                props={this.props}
              />
              <Route
                path="/contracts/account/:token"
                component={AsyncAccount}
                props={this.props}
              />
              <Route
                path="/contracts/payment/:token"
                component={AsyncPayment}
                props={this.props}
              />
              <Route path="/" component={AsyncAppSkeleton} routes={routes} />
            </Switch>
          </ThemeProvider>
        </Router>
        <GlobalStyle />
      </>
    );
  }
}

export default App;
