import * as types from '../actions/types';

const INITIAL_STATE = {
  // authenticated: true,
  // verified: true,
  // user: {
  //   name: 'Jerry Gengerch',
  //   email: 'garry.gergich@pawnee.gov',
  //   image: 'https://d3gqnlyjfmjmla.cloudfront.net/icons/rock.png',
  //   role: 'admin',
  // },
  authenticated: false,
  verified: false,
  user: {
    name: '',
    email: '',
    image: '',
    role: '',
  },
  status: {
    loading: false,
    error: false,
    errorMessage: '',
  },
};

function getHighestRole(userGroupsArray) {
  let highestRole = 'client';

  userGroupsArray.forEach(el => {
    if (el.name.toLowerCase().includes('consultant') && highestRole !== 'admin') {
      highestRole = el.name.toLowerCase();
    } else if (
      el.name.toLowerCase() === 'director' &&
      highestRole !== 'admin'
    ) {
      highestRole = el.name.toLowerCase();
    } else if (el.name.toLowerCase() === 'admin') {
      highestRole = el.name.toLowerCase();
    }
  });

  return highestRole;
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOADING:
      return {
        ...state,
        status: {
          loading: true,
          error: false,
          errorMessage: '',
        },
      };

    case types.AUTH_VERIFIED:
      return {
        ...state,
        authenticated: true,
        verified: true,
        user: {
          name: action.payload.user.name,
          email: action.payload.user.email,
          image: action.payload.user.picture,
          role: getHighestRole(action.payload.user.userGroups),
        },
        status: {
          loading: false,
          error: false,
          errorMessage: '',
        },
      };

    case types.AUTH_USER:
      return {
        ...state,
        authenticated: action.payload,
        verified: true,
        user: {
          name: action.payload.name,
          email: action.payload.email,
          image: action.payload.picture,
          role: 'admin',
        },
      };

    case types.AUTH_ERROR:
      return {
        ...state,
        status: {
          loading: false,
          error: true,
          errorMessage: action.payload,
        },
      };

    case types.AUTH_GOOGLE:
      return {
        ...state,
        authenticated: true,
        verified: true,
        user: {
          name: action.payload.name,
          email: action.payload.email,
          image: action.payload.picture,
          role: getHighestRole(action.payload.userGroups),
        },
      };

    case types.AUTH_SIGNOUT:
      return {
        ...state,
        authenticated: false,
        verified: true,
        user: {
          name: '',
          email: '',
          image: '',
          role: '',
        },
        state: {
          loading: false,
          error: false,
          errorMessage: '',
        },
      };

    default:
      return state;
  }
}
