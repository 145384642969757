import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';
import InlineLoader from '../InlineLoader';
import { InnerNav } from '../styledComponents';

const DashboardReports = styled(InnerNav)`
  display: flex;
  margin-bottom: 20px;
  padding: 20px;
`;

const InlineLoaderContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;

class ContractExecuteChart extends React.Component {
  render() {
    return (
      <div>
        <DashboardReports
          className="bg-white"
          style={{ width: '100%', height: '320px' }}
        >
          {this.props.timeChart.contractsCreatedVsExecuted.loading ? (
            <InlineLoaderContainer>
              <InlineLoader />
            </InlineLoaderContainer>
          ) : (
            <ResponsiveContainer>
              <AreaChart
                height={300}
                data={this.props.timeChart.contractsCreatedVsExecuted.data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis
                  dataKey="name"
                  minTickGap={100}
                  interval="preserveEnd"
                  stroke="#EAF0F4"
                  tick={{
                    fill: '#64798d',
                  }}
                />
                <YAxis
                  minTickGap={25}
                  interval="preserveEnd"
                  stroke="#EAF0F4"
                  tick={{
                    fill: '#64798d',
                  }}
                />
                <CartesianGrid stroke="#EAF0F4" />
                <Tooltip />
                <Legend
                  formatter={value => <Capitalize>{value}</Capitalize>}
                  iconType="circle"
                />
                <Area
                  type="monotone"
                  dataKey="created"
                  stroke="#20669e"
                  fill="#20669e"
                  baseLine={15000}
                  strokeWidth={2}
                  fillOpacity={0.1}
                  dot={{ fill: '#FFFFFF', fillOpacity: 1, r: 4 }}
                  activeDot={{ stroke: '#20669e', fill: '#20669e' }}
                />
                <Area
                  type="monotone"
                  dataKey="executed"
                  stroke="rgb(56, 149, 211,1)"
                  fill="rgb(56, 149, 211,1)"
                  baseLine={15000}
                  strokeWidth={2}
                  fillOpacity={0.1}
                  dot={{ fill: '#FFFFFF', fillOpacity: 1, r: 4 }}
                  activeDot={{ stroke: '#20669e', fill: '#20669e' }}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </DashboardReports>
      </div>
    );
  }
}

export default ContractExecuteChart;
