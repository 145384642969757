import styled from 'styled-components';

export const InnerNav = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  padding: 10px;
  width: 100%;
  border: 1px solid ${props => props.theme.bordergrey};
  margin-bottom: 1em
  width: 100%;
  /* max-width: 80%; */
  h3 {
    margin-bottom: 0px;
    margin-left: 0.5rem;
  }
`;

export const InnerRow = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.bordergrey};
  border-top: none;
  align-items: center;
  height: 52px;
  padding: 5px 10px;
  width: 100%;
  /* max-width: 80%; */
`;

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  padding: 0 10px;
  align-items: center;
  border: 1px solid ${props => props.theme.bordergrey};
  border-top: none;
  width: 100%;
  /* max-width: 80%; */
  .subtitle {
    padding: 5px 0px;
  }
`;

export const Button = styled.button`
  background: ${props => props.theme.text};
  padding: 8px 32px;
  border-radius: 100px;
  background-position: center;
  color: #fff;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
  background-size: cover;
  border: none;
  font-weight: 600;
  transition: all 0.2s ease;

  &:hover {
    box-shadow: 0 8px 10px -3px rgba(0, 0, 0, 0.3);
  }

  &:active {
    box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.1);
  }
`;

export const GradButton = styled(Button)`
  background-image: linear-gradient(-90deg, #22a6df, #3371b7);
`;

export const LightButton = styled(Button)`
  background: ${props => props.theme.white};
  color: ${props => props.theme.text};
`;
export const WideLightButton = styled(Button)`
  background: ${props => props.theme.white};
  color: ${props => props.theme.text};
  width: 100%;
`;

export const VerticalButtonLeft = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  align-items: center;
  color: ${props => props.theme.blue};
  border: none;
  border-right: 1px solid #efefef;
  padding-left: 1em;
  padding-right: 1em;
  height: 100%;
  transition: all 0.2s ease;
`;

export const HorizontalForm = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;

  .ant-input,
  .ant-input-search,
  .ant-select {
    margin-right: 10px;
  }
`;

export const MainTitle = styled.h1`
  font-weight: 400;
  margin-bottom: 0;
`;

export const BusinessName = styled.h2`
  font-weight: 350;
  margin-top: 0;
  margin-bottom: 0.25rem;
  margin-left: 0.5rem;
`;

export const SubTitle = styled.h4`
  font-weight: 300;
  margin-bottom: 0;
  line-height: 0.5em;
`;

export const ActionLinkContainer = styled.div`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: flex-end;

  &.slim {
    a {
      padding: 0.5rem 0.875rem;
    }
  }

  a {
    color: ${props => props.theme.text};
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    align-items: center;
    border: none;
    padding: 1rem 1.75rem;
    border-left: 1px solid ${props => props.theme.bordergrey};
  }
`;

export const ItemBody = styled.div`
  width: 100%;
  padding: 8px;
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.bordergrey};
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
`;

export const BuilderNav = styled.div`
  display: flex;
  background-color: ${props => props.theme.white};
  margin-bottom: 10px;
  align-items: center;
  .ant-menu-horizontal {
    padding: 5px;
    border-bottom: none;
  }
`;

export const FixedNotification = styled.div`
  position: fixed;
  right: 30px;
`;

export const BuilderTitle = styled.div`
  display: grid;
  grid-template-columns: 50% auto;
  margin: 0.25rem 0 0.75rem;
`;

export const PaginationContainer = styled.div`
  margin-top: 10px;
  text-align: center;
`;
