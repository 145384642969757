/* eslint-disable react/display-name */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Input, Select, Modal, Form, Icon, Tooltip } from 'antd';
import styled from 'styled-components';
import * as actions from '../../actions';
import { GradButton } from '../styledComponents';

const { Option } = Select;

const FormItem = Form.Item;

const CreateContractForm = Form.create()(
  class extends React.Component {
    selectSearch = (input, option) =>
      option.props.children.toLowerCase().includes(input.toLowerCase());

    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        templates,
        selectedTemplate,
        confirmLoading,
      } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          visible={visible}
          title="Create a new contract"
          okText="Create"
          onCancel={onCancel}
          onOk={onCreate}
          confirmLoading={confirmLoading}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Tooltip title="Please enter the registered business name. If this is a franchise, please enter “Registered Business Name / Registered Business Location”">
              <FormItem label="Business Name">
                {getFieldDecorator('businessName', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select the name of the business!',
                    },
                  ],
                })(<Input />)}
              </FormItem>
            </Tooltip>
            <Tooltip title="Please enter the first and last name of the client who will be signing the contract.">
              <FormItem label="Client's Name">
                {getFieldDecorator('clientName', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input the name of the client!',
                    },
                  ],
                })(<Input />)}
              </FormItem>
            </Tooltip>
            <FormItem label="Client's Email">
              {getFieldDecorator('clientEmail', {
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not a valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input the email of the client!',
                  },
                ],
              })(<Input type="email" />)}
            </FormItem>
            <FormItem
              label="Template"
              className="collection-create-form_last-form-item"
            >
              {getFieldDecorator('templateId', {
                rules: [
                  { required: true, message: 'Please select a template!' },
                ],
                initialValue: selectedTemplate ? selectedTemplate.id : '',
              })(
                <Select
                  showSearch
                  filterOption={this.selectSearch}
                  onChange={this.select}
                >
                  {templates.length > 0 &&
                    templates.map(obj => (
                      <Option key={obj.id} value={obj.id}>
                        {obj.name}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
`;

class ContractCreateModal extends Component {
  state = {
    visible: false,
    redirect: null,
    clientName: '',
    clientEmail: '',
    templateId: '',
    confirmLoading: false,
  };

  componentDidMount() {
    this.props.fetchTemplatesModal();
  }

  showModal = e => {
    e.preventDefault();
    this.setState({
      visible: true,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    this.setState({ confirmLoading: true });
    form.validateFields((err, values) => {
      if (err) {
        this.setState({ confirmLoading: false });
        return;
      }

      // console.log('Received values of form: ', values);

      this.props.createContract(values, res => {
        // console.log('res: ', res);

        this.setState({
          visible: false,
          redirect: <Redirect to="/contracts/build" />,
          businessName: '',
          clientName: '',
          clientEmail: '',
          templateId: '',
          confirmLoading: false,
        });

        form.resetFields();
      });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  handleChange = e => {
    const { name, type, value } = e.target;
    const val = type === 'number' ? parseFloat(value) : value;
    this.setState({ [name]: val });
  };

  select = value => {
    console.log('e: ', value);
    this.setState({ templateId: value });
  };

  render() {
    return (
      <OuterContainer>
        {this.props.useActionLink ? (
          <a href="/#" onClick={this.showModal}>
            <Icon type="plus" />
            Create From Template
          </a>
        ) : (
          <GradButton onClick={this.showModal}>Create Contract</GradButton>
        )}
        <CreateContractForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          templates={this.props.templates}
          selectedTemplate={this.props.selectedTemplate}
          confirmLoading={this.state.confirmLoading}
        />
        {this.state.redirect}
      </OuterContainer>
    );
  }
}

const mapStateToProps = state => ({
  templates: state.search.templateSearchResults,
});

export default connect(mapStateToProps, actions)(ContractCreateModal);
