import axios from 'axios';
import * as types from './types';
import config from '../config';
const api = config.api.URL;

export const fetchContractTimeReport = (
  queryString,
  callBack
) => async dispatch => {
  try {
    callBack = callBack ? callBack : () => {};
    const response = await axios.get(`${api}/contracts/report?${queryString}`);

    if (response.status === 200) {
      dispatch({
        type: types.LOAD_CONTRACT_TIME_DATA,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: types.API_ERROR,
    });
  }
};
