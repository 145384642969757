import * as types from './types';

//UI Actions
export const showGlobalLoader = message => dispatch => {
  try {
    dispatch({
      type: types.SHOW_GLOBAL_LOADER,
      payload: message,
    });
  } catch (e) {
    console.log('toggle global loader error: ', e);
  }
};

export const hideGlobalLoader = () => dispatch => {
  try {
    dispatch({
      type: types.HIDE_GLOBAL_LOADER,
      payload: {},
    });
  } catch (e) {
    console.log('toggle global loader error: ', e);
  }
};

export const setContractLoading = () => dispatch => {
  try {
    dispatch({
      type: types.CONTRACT_LOADING,
      payload: {},
    });
  } catch (e) {
    console.log('toggle contract loading error: ', e);
  }
};

export const setContractLoadingDone = () => dispatch => {
  try {
    dispatch({
      type: types.CONTRACT_LOADING_DONE,
      payload: {},
    });
  } catch (e) {
    console.log('toggle contract loading error: ', e);
  }
};

export const focusProduct = (item, callback) => dispatch => {
  try {
    dispatch({
      type: types.FOCUS_PRODUCT,
      payload: item.id,
    });

    if (callback) {
      callback();
    }
  } catch (e) {
    console.log('create template error: ', e);
  }
};

export const focusActivityTab = tab => dispatch => {
  try {
    dispatch({
      type: types.FOCUS_ACTIVITY_TAB,
      payload: tab,
    });
  } catch (e) {
    console.log('create template error: ', e);
  }
};

export const resetBuilderUI = tab => dispatch => {
  try {
    dispatch({
      type: types.RESET_BUILDER_UI,
    });
  } catch (e) {
    console.log('create template error: ', e);
  }
};

export const toggleExecutedPdfLoading = message => dispatch => {
  try {
    dispatch({
      type: types.EXECUTED_PDF_LOADING,
    });
  } catch (e) {
    console.log('mark executed pdf loading error: ', e);
  }
};

export const executedPdfGenerating = message => dispatch => {
  try {
    dispatch({
      type: types.EXECUTED_PDF_GENERATING,
      payload: message,
    });
  } catch (e) {
    console.log('mark executed pdf loading error: ', e);
  }
};

export const showFeedbackModal = () => dispatch => {
  try {
    dispatch({
      type: types.SHOW_FEEDBACK_MODAL,
    });
  } catch (e) {
    console.log('Error when showing modal', e);
  }
};
