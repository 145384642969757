import { AutoComplete, DatePicker, Input } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import RangPickerExtraFooter from './../RangPickerExtraFooter';

const { RangePicker } = DatePicker;
const AutoCompleteOption = AutoComplete.Option;

class DashboardFilters extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      autocompleteConsultantResult: [],
      setEmptyDateRange: false,
      filters: {
        consultantId: 0,
        startDate: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'days'),
        endDate: moment(new Date(), 'YYYY-MM-DD'),
      }
    }
    this.setDateRange = this.setDateRange.bind(this);
  }

  componentDidMount() {
    let { filters } = this.state;
    filters.startDate = filters.startDate.format('YYYY-MM-DD');
    filters.endDate = filters.endDate.format('YYYY-MM-DD');
    let queryString = null;

    if (this.props.auth.user.role === 'admin') {
      queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
      this.props.fetchContractTimeReport(queryString);
    } else {
      this.props.searchConsultants(this.props.auth.user.email, () => {
        let consultantId = this.props.consultants[0]['id'];
        filters.consultantId = consultantId;
        queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
        this.props.fetchContractTimeReport(queryString);
        this.setState({ filters });
      });
    }
  }

  showAutoCompleteConsultants = value => {
    let consultantInputValue = value;
    if (consultantInputValue !== '') {
      this.props.searchConsultants(consultantInputValue, () => {
        this.setState({ autocompleteConsultantResult: this.props.consultants });
      });
    } else {
      // when consultantInputValue is empty
      let { filters } = this.state;
      filters.consultantId = 0;
      let queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
      this.props.fetchContractTimeReport(queryString);
      this.setState({
        filters
      });
    }
  };

  handleConsultantChange = (value, option) => {
    let { filters } = this.state;
    filters.consultantId = value;
    this.setState({
      filters
    });
    let queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
    this.props.fetchContractTimeReport(queryString);
  };

  handleDateRangeChange = value => {
    let { filters, setEmptyDateRange } = this.state;
    let rangeValue = value;
    let startDate
    let endDate
    if (rangeValue.length) {
      startDate = rangeValue[0].format('YYYY-MM-DD');
      endDate = rangeValue[1].format('YYYY-MM-DD');
      setEmptyDateRange = false;
    } else {
      startDate = moment(new Date(), 'YYYY-MM-DD').subtract(1, 'days');
      endDate = moment(new Date(), 'YYYY-MM-DD');
      setEmptyDateRange = true;
    }

    filters.startDate = startDate;
    filters.endDate = endDate;
    this.setState({
      filters,
      setEmptyDateRange
    });
    let queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
    this.props.fetchContractTimeReport(queryString);
  };

  setDateRange(value) {
    this.setState({
      setEmptyDateRange: false
    });
    let filters = this.getUpdatedFilters(value);
    let queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
    this.props.fetchContractTimeReport(queryString);
    this.setState({
      filters
    });
  }

  getUpdatedFilters(value) {
    let { filters } = this.state;
    let subtractDays = 30;
    switch (value) {
      case 'last_week':
        subtractDays = 7
        break;

      case 'last_2_weeks':
        subtractDays = 14
        break;

      case 'last_30_days':
        subtractDays = 30
        break;

      case 'last_60_days':
        subtractDays = 60
        break;

      case 'last_90_days':
        subtractDays = 90
        break;

      case 'last_180_days':
        subtractDays = 180
        break;

      case 'year_to_date':
        subtractDays = 365
        break;

      case 'last_year':
        subtractDays = 730
        break;
      default:
    }
    let startDate = moment(new Date(), 'YYYY-MM-DD').subtract(subtractDays, 'days').format('YYYY-MM-DD');
    let endDate = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
    if (subtractDays === 730) {
      endDate = moment(new Date(), 'YYYY-MM-DD').subtract(365, 'days').format('YYYY-MM-DD');
    }
    filters.startDate = startDate;
    filters.endDate = endDate;
    return filters;
  }

  render() {

    const { autocompleteConsultantResult, filters } = this.state;
    let consultantAutocompleteInput = null;
    if (this.props.auth.user.role === 'admin') {
      const consultantOptions = autocompleteConsultantResult.map((consultant, index) => (
        <AutoCompleteOption key={consultant.id}>
          {consultant.name + ' - ' + consultant.email}
        </AutoCompleteOption>
      ));
      consultantAutocompleteInput = <AutoComplete style={{ margin: '5px' }}
        dataSource={consultantOptions}
        onChange={this.showAutoCompleteConsultants}
        onSelect={this.handleConsultantChange}
        placeholder="Filter by Consultant"
      ><Input style={{ width: "300px" }} />
      </AutoComplete>
    }

    let RangePickerValue = [
      moment(filters.startDate, 'YYYY-MM-DD'),
      moment(filters.endDate, 'YYYY-MM-DD')
    ];
    if(this.state.setEmptyDateRange) {
      RangePickerValue = []
    }

    return (
      <div style={{ textAlign: 'right', }}>
        {consultantAutocompleteInput}
        <RangePicker style={{ margin: '5px' }}
          format="MMM DD, YYYY"
          defaultValue={[filters.startDate, filters.endDate]}
          value={RangePickerValue}
          onChange={this.handleDateRangeChange}
          separator="~"
          disabledDate={current => {
            return current > moment(new Date());
          }}
          renderExtraFooter={() => <RangPickerExtraFooter
            setDateRange={this.setDateRange}
          />}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  consultants: state.search.consultants
});

export default connect(
  mapStateToProps,
  actions
)(DashboardFilters);
