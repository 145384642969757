import { Col, Row } from 'antd';
import React from 'react';
import { Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import styled from 'styled-components';
import InlineLoader from '../InlineLoader';
import { InnerNav } from '../styledComponents';

const DashboardUserReport = styled(InnerNav)`
  display: flex;

  align-items: center;
  justify-content: center;
`;

const Title = styled.text`
  font: bold 5vh sans-serif;
  fill: #6baed6;
`;
const Subtitle = styled.text`
  font: 2vh sans-serif;
  fill: #000;
`;

class ContractStatReport extends React.Component {
  render() {
    let winPercentageComponent = <InlineLoader />;
    if (this.props.circleGraphs.winPercentage.loading === false) {
      winPercentageComponent = (
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={this.props.circleGraphs.winPercentage.data}
              dataKey="value"
              innerRadius="60%"
              startAngle={90}
              endAngle={450}
            >
              <Label
                content={target => (
                  <g >
                    <Title y="50%" x="50%" textAnchor="middle">
                      {target.value.title.text}
                    </Title>
                    <Subtitle y="50%" x="50%" dy={20} textAnchor="middle">
                      {target.value.subTitle.text}
                    </Subtitle>
                  </g>
                )}
                position="center"
                value={this.props.circleGraphs.winPercentage}
              />
            </Pie>

            <Tooltip />
            {/* <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            iconType="circle"
          /> */}
          </PieChart>
        </ResponsiveContainer>
      );
    }

    let contractsFinalizedComponent = <InlineLoader />;
    if (this.props.circleGraphs.contractsFinalized.loading === false) {
      contractsFinalizedComponent = (
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={this.props.circleGraphs.contractsFinalized.data}
              cy="50%"
              cx="50%"
              dataKey="value"
              innerRadius="60%"
              startAngle={90}
              endAngle={450}
            >
              <Label
                content={target => (
                  <g transform="translate(-75,0)">
                    <Title y="50%" x="50%" textAnchor="middle">
                      {target.value.title.text}
                    </Title>
                    <Subtitle y="50%" x="50%" dy={20} textAnchor="middle">
                      {target.value.subTitle.text}
                    </Subtitle>
                  </g>
                )}
                position="center"
                value={this.props.circleGraphs.contractsFinalized}
              />
            </Pie>

            <Tooltip />
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              iconType="circle"
              width={150}
            />
          </PieChart>
        </ResponsiveContainer>
      );
    }

    return (
      <DashboardUserReport className="bg-white">
        <Row gutter={8} type="flex" style={{ width: '100%' }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div
              className="ant-card ant-card-bordered"
              style={{ height: '400px' }}
            >
              {winPercentageComponent}
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div
              className="ant-card ant-card-bordered"
              style={{ height: '400px' }}
            >
              {contractsFinalizedComponent}
            </div>
          </Col>
        </Row>
      </DashboardUserReport>
    );
  }
}

export default ContractStatReport;
