import axios from 'axios';
import * as types from './types';
import config from '../config';
const api = config.api.URL;
axios.defaults.withCredentials = true;
// if (api === 'http://0.0.0.0:47764/rest/v1') {
// }

export const verifyAuth = () => async dispatch => {
  try {
    const response = await axios.get(`${api}/auth/verify`);

    if (response.status === 200) {
      dispatch({
        type: types.AUTH_VERIFIED,
        payload: response.data,
      });
    } else {
      dispatch({
        type: types.AUTH_SIGNOUT,
      });
    }
  } catch (e) {
    dispatch({
      type: types.AUTH_SIGNOUT,
    });
  }
};

export const googleLogIn = returnUrl => async dispatch => {
  try {
    dispatch({
      type: types.AUTH_LOADING,
    });

    const response = await axios.get(
      `${api}/auth/getGoogleTokens?returnUrl=${returnUrl}`
    );
    window.location.replace(response.data.url);
  } catch (e) {
    dispatch({
      type: types.AUTH_ERROR,
      payload: 'An API error occurred. Please try again.',
    });
  }
};

export const postGoogleToken = (
  code,
  location,
  redirectOnSuccess
) => async dispatch => {
  try {
    const response = await axios.post(`${api}/auth/googleCb`, {
      code: code,
      location: location,
    });

    const redirectTo = decodeURIComponent(response.data.location);

    if (api === 'http://0.0.0.0:47764/rest/v1') {
      document.cookie = `ctoken=${response.data.jwt}`;
    }

    setTimeout(() => {
      dispatch({
        type: types.AUTH_GOOGLE,
        payload: response.data,
      });
      redirectOnSuccess(redirectTo);
    }, 1000);
  } catch (e) {
    dispatch({
      type: types.AUTH_ERROR,
      payload: 'Unable to authenticate user. Please try again.',
    });
    console.log('error on googleLogIn: ', e);
  }
};

export const signout = () => async dispatch => {
  try {
    const response = await axios.get(`${api}/auth/signout`);

    if (response.status === 200) {
      dispatch({
        type: types.AUTH_SIGNOUT,
        payload: null,
      });
    } else {
      throw new Error('Error signing out.');
    }
  } catch (e) {
    // error on signout will just signout =)
  }
};
