import * as types from '../actions/types';

const INITIAL_STATE = {
  filter: '',
  contractSearchResults: [],
  contractErrorMessage: '',
  templateSearchResults: [],
  templateErrorMessage: '',
  userSearchResults: [],
  selectedUser: {},
  consultants:[]
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_TEMPLATES:
      const templateSearchResults = action.payload.response;
      const templatesPagination = action.payload.pagination;
      return {
        ...state,
        templateSearchResults: templateSearchResults,
        templateErrorMessage: '',
        templatesPagination,
        filter: action.payload.filter,
      };
    case types.FETCH_TEMPLATES_ERROR:
      return { ...state, templateErrorMessage: action.payload };
    case types.FETCH_CONTRACTS:
      const contractSearchResults = action.payload.response;
      const { pagination } = action.payload;
      return {
        ...state,
        contractSearchResults: contractSearchResults,
        contractErrorMessage: '',
        pagination,
        filter: action.payload.filter,
      };
    case types.FETCH_CONTRACTS_ERROR:
      return { ...state, contractErrorMessage: action.payload };

    case types.SEARCH_USERS:
      return {
        ...state,
        userSearchResults: action.payload,
      };
    case types.SELECT_USER:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          id: action.payload,
        },
      };

    case types.SEARCH_USERS_ERROR:
      return { ...state, contractErrorMessage: action.payload };
    case types.SEARCH_CONSULTANTS:
      return {
        ...state,
        consultants: action.payload,
      };

    case types.SOFT_DELETE_TEMPLATE:
      const { deletedTemplate } = action.payload;

      const newTemplateSearchResults = state.templateSearchResults.filter(
        temp => temp.id !== deletedTemplate.id
      );

      return {
        ...state,
        templateSearchResults: newTemplateSearchResults,
      };

    case types.COPY_TEMPLATE_SUCCEEDED:
      const copiedTemplate = action.payload;

      return {
        ...state,
        templateSearchResults: [copiedTemplate, ...state.templateSearchResults],
      };
    case types.DELETE_CONTRACT: {
      const deletedContract = action.payload;

      const newResults = state.contractSearchResults.filter(
        temp => temp.id !== deletedContract.id
      );

      return {
        ...state,
        contractSearchResults: newResults,
      };
    }

    case types.CREATE_NEW_USER_FINISHED: {
      const newUser = action.payload;

      newUser.usergroup = JSON.stringify(newUser.userGroups);

      return {
        ...state,
        userSearchResults: [...state.userSearchResults, newUser],
      };
    }

    case types.UPDATE_USER_FINISHED: {
      const updatedUser = action.payload;

      const filteredState = state.userSearchResults.filter(
        user => user.id !== updatedUser.id
      );

      const newUser = {
        ...updatedUser,
        usergroup: JSON.stringify(updatedUser.userGroups),
      };

      return {
        ...state,
        userSearchResults: [newUser, ...filteredState],
      };
    }

    case types.DELETE_USER_FINISHED: {
      const deletedUser = action.payload;

      const filteredState = state.userSearchResults.filter(
        user => user.id !== deletedUser.id
      );

      return {
        ...state,
        userSearchResults: filteredState,
      };
    }
    default:
      return state;
  }
}
