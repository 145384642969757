import axios from 'axios';
import config from '../config';

const api = config.api.URL;

export const getZuoraDigitalSignature = (contract, type, callBack) => async dispatch => {
  try {
    const response = await axios.get(
      `${api}/billing/zuora-digital-signature/${contract.signerToken}`,
      {
        params: {
          isCanadian: contract.isCanadian,
          type,
        },
      }
    );
    callBack(response.data.response);
  } catch (error) {
    console.log('Get Zuora Digital Signature error: ', error);
  }
};

export const savePaymentMethod = (contract, paymentMethodId, callBack) => async dispatch => {
  try {
    await axios.put(
      `${api}/billing/save-payment-method/${contract.signerToken}`,
      {
        paymentMethodId
      }
    );
    callBack(true);
  } catch (error) {
    console.log('Save Payment Method error: ', error);
    callBack(false);
  }
};

export const checkLeadIdUsage = (leadId, callBack) => async dispatch => {
  try {
    await axios.get(`${api}/billing/lead-id-usage/${leadId}`);
    callBack(true, 'Valid');
  } catch (error) {
    if (error.response.status === 409) {
      callBack(false, `Lead ID ${leadId} is already in use`);
    } else if (error.response.status === 404) {
      callBack(false, `Invalid Lead ID`);
    } else {
      callBack(
        false,
        `Unable to determine if Lead ID ${leadId} is in use, please try again`
      );
    }
  }
};

export const createBillingAccount = (contract, values, callBack) => async dispatch => {
  try {
    await axios.post(
      `${api}/billing/account/${contract.signerToken}`,
      values,
      {
        params: {
          isCanadian: contract.isCanadian,
        },
      }
    );
    callBack(true);
  } catch (error) {
    console.log('Create Billing Account error: ', error);
    callBack(false);
  }
};
