import { Button } from 'antd';
import React from 'react';

const RangPickerExtraFooterBtnClass = {
  borderColor: 'rgb(153, 204, 255)',
  color: 'rgb(51, 153, 255)',
  background: '#fff',
  marginRight: "5px"
}

class RangPickerExtraFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateLevelOptions: [
        {key: 'last_week', value: 'Last Week'},
        {key: 'last_2_weeks', value: 'Last 2 Weeks'},
        {key: 'last_30_days', value: 'Last 30 Days'},
        {key: 'last_60_days', value: 'Last 60 Days'},
        {key: 'last_90_days', value: 'Last 90 Days'},
        {key: 'last_180_days', value: 'Last 180 Days'},
        {key: 'year_to_date', value: 'Year to Date'},
        {key: 'last_year', value: 'Last Year'}
      ]
    }
  }

  render() {
    const listItems = this.state.dateLevelOptions.map((link) =>
        <Button
        shape="round"
        type="primary"
        size="small"
        style={ RangPickerExtraFooterBtnClass }
        key={link.key}
        onClick={ () => this.props.setDateRange(`${link.key}`) }>{link.value}</Button>
    );
    return (
      <div>
        {listItems}
      </div>
    )
  }
}

export default RangPickerExtraFooter;
