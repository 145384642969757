import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

const LoadingMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 6em 0;

  &.slim {
    margin: 1em 0;
  }
`;

const LoadingLogoContainer = styled.div`
  display: inline-block;
  margin-bottom: 2em;
`;

function InlineLoader(props) {
  return (
    <LoadingMessage className={props.slim ? 'slim' : ''}>
      {props.showLogo && (
        <LoadingLogoContainer>
          <img
            src="https://d3gqnlyjfmjmla.cloudfront.net/icons/logo-marketing360-dark-large.png"
            alt="logo"
          />
        </LoadingLogoContainer>
      )}
      <Icon type="loading" style={{ fontSize: '32px' }} />
      {!props.slim && <h3>Loading</h3>}
    </LoadingMessage>
  );
}

export default InlineLoader;
