import axios from 'axios';
import config from '../config';
import * as types from '../actions/types';

axios.defaults.withCredentials = true;
const api = config.api.URL;

export default ({ dispatch, getState }) => next => async action => {
  next(action); // pass action to the rest of the middleware
  const state = getState();
  const contract = state.contract.present;
  // autosave is only triggered on actions related to contract builder

  if (
    action.type === types.CONTRACT_AUTOSAVE_SUCCEEDED &&
    contract.contractTemplate
  ) {
    try {
      const response = await axios.get(
        `${api}/contracts/${contract.id}/totals`
      );

      dispatch({
        type: types.GET_CONTRACT_TOTALS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: types.API_ERROR,
        payload: 'Could update contract totals.',
      });
    }
  }
};
