import React from 'react';
import asyncComponent from './AsyncComponent';
import Dashboard from './dashboard/Dashboard';
import ActionLog from './ActionLog';

const AsyncTemplate = asyncComponent(() => import('./template/Template'));

const AsyncTemplateActivity = asyncComponent(() =>
  import('./template/TemplateActivity')
);

const AsyncContract = asyncComponent(() => import('./contract/Contract'));

// const AsyncSettings = asyncComponent(() => import('./settings/Settings'));

const AsyncUsers = asyncComponent(() => import('./users/Users'));

// const AsyncReports = asyncComponent(() => import('./reports/Reports'));

export default [
  {
    path: '/',
    mainExact: true,
    navExact: true,
    activExact: true,
    name: 'Dashboard',
    private: false,
    menuIcon:
      'https://d3gqnlyjfmjmla.cloudfront.net/icons/m360-icon35-home.png',
    main: () => <Dashboard />,
    // nav: () => <DashboardMenu />,
    activ: () => <ActionLog />,
  },
  {
    path: '/templates',
    name: 'Templates',
    private: true,
    menuIcon:
      'https://d3gqnlyjfmjmla.cloudfront.net/icons/m360-icon35-loan.png',
    main: () => <AsyncTemplate />,
    // nav: () => <AsyncTemplateMenu />,
    activ: () => <AsyncTemplateActivity />,
  },
  {
    path: '/contracts',
    name: 'Contracts',
    private: true,
    mainExact: false,
    navExact: false,
    activExact: true,
    menuIcon:
      'https://d3gqnlyjfmjmla.cloudfront.net/icons/m360-icon35-check.png',
    main: () => <AsyncContract />,
    // nav: () => <AsyncContractMenu />,
  },
  {
    path: '/users',
    name: 'Users',
    private: true,
    menuIcon:
      'https://d3gqnlyjfmjmla.cloudfront.net/icons/m360-icon35-user.png',
    main: () => <AsyncUsers />,
    // nav: () => <AsyncUsersMenu />,
  },
  // {
  //   path: '/settings',
  //   name: 'Settings',
  //   private: true,
  //   menuIcon:
  //     'https://d3gqnlyjfmjmla.cloudfront.net/icons/m360-icon35-gear.png',
  //   main: () => <AsyncSettings />,
  //   // nav: () => <AsyncSettingsMenu />,
  // },
  // {
  //   path: '/reports',
  //   name: 'Reports',
  //   private: true,
  //   menuIcon:
  //     'https://d3gqnlyjfmjmla.cloudfront.net/icons/m360-icon35-chart.png',
  //   main: () => <AsyncReports />,
  //   // nav: () => <AsyncReportsMenu />,
  // },
];
