import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import autoSave from './middlewares/autoSave';
import updateContractTotals from './middlewares/updateContractTotals';
import notification from './middlewares/notification';

export default ({ children, initialState }) => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(
      applyMiddleware(reduxThunk, autoSave, notification, updateContractTotals)
    )
  );

  return <Provider store={store}>{children}</Provider>;
};
